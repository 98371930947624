import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from '../../_services/user.service';



@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {
  }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Capture the originally requested URL into localStorage
    const loggedIn = this.userService.logged_in;
    if (next && !loggedIn) {
      localStorage.setItem('login_dest_url', state.url);
    }
    if(loggedIn)  {
      return true;
    } else {
      this.router.navigate(['/public/login']);
      return false;
    }
  }}
