import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  private host: string = environment.backend_url;

  constructor(private http: HttpClient) {
  }

  public register(firstName, lastName, username, company) {
    return this.oauthRequest(firstName, lastName, username, company);
  }


  private oauthRequest(firstName, lastName, username, company) {
    return this.http.post<any>(`${this.host}/signup`, {
        firstName: firstName,
        lastName: lastName,
        username: username,
        company: company
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );
  }
}
