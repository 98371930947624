import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NoticeButtonCallback, NoticeType } from "../../_models/NoticeMessage";


@Component({
  selector: 'app-vaa-notice',
  templateUrl: './vaa-notice.component.html',
  styleUrls: ['./vaa-notice.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VaaNoticeComponent implements OnInit {
  public _content: TextContentInterface[] = [];
  _contentRaw: string|TextContentInterface;
  @Input() show: boolean = true;

  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() noticeType: NoticeType = NoticeType.notice;

  @Input() button?: NoticeButtonCallback;

  @Input() title: string;

  constructor() { }

  ngOnInit() {
  }

  get content() {
    return this._contentRaw;
  }
  @Input() set content(value: any) {
    this._contentRaw = value;
    if (typeof value == 'string') {
      this._content = [
        {
          text: value as string
        }
      ]
    }
    else {
      this._content = value as TextContentInterface[];
    }
  }

  hide() {
    this.show = false;
    this.showChange.emit(this.show);
  }
}


export class TextContentInterface {
  text: string;
  path?: string;
}

