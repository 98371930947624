import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Tab } from '../../_interfaces/tab';
import { HorizontalTabsService } from '../../_services/horizontal-tabs.service';
import { PasswordResetService } from "../../_services/password-reset.service";
import { Message } from "../../_interfaces/Message";
import { VaaNoticesService } from "../../_services/VaaNoticesService";


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public activeRoute: string;
  public links: Tab[] = [];

  constructor(private router: Router,
              private htService: HorizontalTabsService,
              private vaaNoticesService: VaaNoticesService,
              private passwordReset: PasswordResetService) { }

  ngOnInit() {
    this.activeRoute = '/public/password-reset';
    this.links = this.htService.getUserLinks();
  }

  onSubmit(f) {
    if ( f.controls.username.status === 'INVALID' ) return false;

    this.passwordReset.reset_request(f.value.username).subscribe((message: Message) => {
      this.vaaNoticesService.success(message.message);
    });
  }

}
