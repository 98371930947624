import { Attribute, JsonApiModel, JsonApiModelConfig } from "angular2-jsonapi";

class alertRuleIatas {
  id: Number = null;
  to_iata: string = null;
  from_iata: string = null;
}

class alertRuleStates {
  id: Number = null;
  from_state: string = null;
  to_state: string = null;
}

class alertRuleDateTimes {
  id: Number = null;
  from_datetime: string = null;
  to_datetime: string = null;
}

@JsonApiModelConfig({
  type: 'AlertRuleSet'
})

export class AlertRuleSet extends JsonApiModel {
  @Attribute()
  title: string;

  @Attribute()
  created_date: Date;

  @Attribute()
  longevity: Number;

  @Attribute()
  alertRuleIatas: alertRuleIatas[];

  @Attribute()
  alertRuleStates: alertRuleStates[];

  @Attribute()
  alertRuleDateTimes: alertRuleDateTimes[];

  // @Attribute()
  // notification_interval: Number;

  @Attribute()
  flexibleTime: Boolean;

  get originValue() {
    if (this.firstIata.from_iata) {
      return this.firstIata.from_iata;
    }
    if (this.firstSate.from_state) {
      return this.firstSate.from_state;
    }
  }

  get originDisplay() {
    if (this.originValue) {
      return this.originValue
    }
    return 'Empty';
  }

  get destinationValue() {
    if (this.firstIata.to_iata) {
      return this.firstIata.to_iata;
    }

    if (this.firstSate.to_state) {
      return this.firstSate.to_state;
    }
  }

  get destinationDisplay() {
    if (this.destinationValue) {
      return this.destinationValue;
    }

    return 'Empty';
  }

  get firstIata() {
    if (this.alertRuleIatas.length) {
      return this.alertRuleIatas[0];
    }
    return new alertRuleIatas();
  }

  get firstSate() {
    if (this.alertRuleStates.length) {
      return this.alertRuleStates[0];
    }
    return new alertRuleStates();
  }
  get firstDate() {
    if (this.alertRuleDateTimes.length) {
      return this.alertRuleDateTimes[0];
    }
    return new alertRuleDateTimes();
  }
  get fromDateTime() {
    if( this.firstDate.from_datetime ) {
      return this.firstDate.from_datetime;
    }
    return 'Empty';
  }
  get toDateTime() {
    if(this.firstDate.to_datetime) {
      return this.firstDate.to_datetime;
    }
    return 'Empty';
  }
}
