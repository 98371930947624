import {Component, Input, OnInit} from '@angular/core';
import {Fare} from '../../../_models/Fare';
import {ShareFareService} from '../../../_services/fares/share-fare.service';

@Component({
  selector: 'app-fare-table-share',
  templateUrl: './fare-table-share.component.html',
  styleUrls: ['./fare-table-share.component.scss']
})
export class FareTableShareComponent implements OnInit {

  @Input() rowData: Fare;

  public enableShare: boolean;
  public saleTitle: string;

  constructor(private shareFareService: ShareFareService) {}

  ngOnInit() {
    if (this.rowData.url && this.rowData.url !== '') {
      this.enableShare = true;
      this.showDeeplinkedFare();
    } else {
      this.enableShare = false;
    }
  }

  shareLinkClick(ev) {
    ev.preventDefault();
    this.openShareModal();
  }

  openShareModal() {
    if (!this.shareFareService.currentlyOpen) {
      this.shareFareService.setFare(this.rowData);
      this.shareFareService.openShareFareDialog();
    }
  }

  showDeeplinkedFare() {
    if (this.shareFareService.deeplinkId) {
      if (this.rowData.id === this.shareFareService.deeplinkId) {
        this.openShareModal();
      }
    }
  }
}
