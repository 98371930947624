import { Injectable } from "@angular/core";
import { DataStoreService } from "./data-store.service";
import { Content } from "../_models/Content";
import { Observable, Subscriber } from "rxjs";
import { shareReplay } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  private _obs = {};
  constructor(private dataStoreService: DataStoreService) {  }

  getContent(id, refresh:boolean = true) {
    if (!this._obs[id]) {
      this._obs[id] = {};
      this._obs[id].obs = new Observable<Content>((subscriber: Subscriber<Content>) => {
        this._obs[id].sub = subscriber;
      }).pipe(shareReplay(1)); // all subs are updated with the last loaded content
      this._obs[id].obs.subscribe(() => {
        this._obs[id].loading = false;
      })
    }
    if (!this._obs[id].loading || refresh == false) {
      this.dataStoreService.findRecord(Content, id).subscribe(this._obs[id].sub);
      this._obs[id].loading = true;
    }
    return this._obs[id].obs;
  }
}
