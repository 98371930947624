import { Attribute, JsonApiModel, JsonApiModelConfig } from "angular2-jsonapi";
import { ProfileInterface } from "../_interfaces/ProfileInterface";

@JsonApiModelConfig({
  type: 'Profile'
})
export class Profile extends JsonApiModel implements ProfileInterface {
  @Attribute()
  email: string;
  @Attribute()
  first_name: string;
  @Attribute()
  last_name: string;
  @Attribute()
  receive_partner_platform_email: boolean;
  @Attribute()
  is_partner_platform_user: boolean;
  @Attribute()
  subscription_status: string;
}
