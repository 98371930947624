import { HttpClient } from '@angular/common/http';
import { LoginMessage } from "../_interfaces/LoginMessage";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private host:string = environment.backend_url;

  constructor(private http: HttpClient) {
  }

  public login(email, password) {
    return this.oauthRequest(email, password);
  }

  // @todo refreah logic
  public refreash() {
    let a = 1;
  }

  private oauthRequest(email, password) {
    let observable = this.http.post<LoginMessage>(`${this.host}/login`, {
        username: email,
        password: password,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );
    return observable;
  }
}
