import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {filter} from 'rxjs/operators';
import {DataStoreService} from "../../../_services/data-store.service";
import {isNull} from "util";
import {Content} from "../../../_models/Content";
import {of} from "rxjs/index";
import { UserService } from "../../../_services/user.service";

@Component({
  selector: 'app-two-col',
  templateUrl: './two-col.component.html',
  styleUrls: ['./two-col.component.scss']
})
export class TwoColComponent implements OnInit {
  public currentRoute: string;
  public showNotice = true;
  public noticeContent = '';

  constructor(private router: Router, private dataStore: DataStoreService, private userService: UserService) {
  }
  ngOnInit(): void {
    // Get the initial route.
    this.currentRoute  = this.router.url;

    // Listen for future route changes as this init function only fires when the parent route changes.
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.currentRoute = event.url;
    });

    let peek = this.dataStore.withAuth(this.userService.login_token).peekRecord(Content, 'private_notice');
    let observable = isNull(peek) || typeof peek == 'undefined'
      ? this.dataStore.withAuth(this.userService.login_token).findRecord(Content, 'private_notice')
      : of(peek);
    observable.subscribe((Content) => {
      this.noticeContent = Content.value;
    });
  }
}
