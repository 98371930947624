import { Component, OnInit } from '@angular/core';
import { UserService } from "../_services/user.service";

import md5 from 'md5'

@Component({
  selector: 'app-user-badge',
  templateUrl: './user-badge.component.html',
  styleUrls: ['./user-badge.component.scss']
})
export class UserBadgeComponent implements OnInit {
  email_hash: string;
  email: string;

  constructor(userService: UserService) {
    userService.profileObservable.subscribe(profile => {
      this.email_hash = md5(profile.email);
      this.email = profile.email;
    });
  }

  ngOnInit() {
  }

}
