import { Component, OnInit } from '@angular/core';
import { UserService } from "../_services/user.service";
import { User } from "../_models/User";
import { Router } from "@angular/router";

@Component({
  selector: 'app-waiting-for-approval',
  templateUrl: './waiting-for-approval.component.html',
  styleUrls: ['./waiting-for-approval.component.scss']
})
export class WaitingForApprovalComponent implements OnInit {

  constructor(private userService: UserService, private router:Router) { }

  ngOnInit() {
    this.userService.loadUserInfo(false).subscribe((user: User) => {
      if (user.approved) {
        let command = this.userService.loginDestination(user);
        this.router.navigateByUrl(command);
      }
    });
  }

}
