import { Component, OnInit } from '@angular/core';
import { HorizontalTabsService } from '../../_services/horizontal-tabs.service';
import { Tab } from '../../_interfaces/tab';
import { DataStoreService } from "../../_services/data-store.service";
import { Fare } from "../../_models/Fare";
import { JsonApiQueryData } from "angular2-jsonapi";
import { AlertRuleSet } from "../../_models/AlertRuleSet";
import { UserService } from "../../_services/user.service";
import { NewAlertComponent } from "../new-alert/new-alert.component";
import { MatDialog } from "@angular/material/dialog";
import { LoadIndicatorService } from '../../_services/load-indicator.service';
import { DateRange } from "../../_interfaces/DateRange";

interface FaresByRuleSet {
  rule: AlertRuleSet
  fares: Fare[]
}

@Component({
  templateUrl: './matched-fares.component.html',
  styleUrls: ['./matched-fares.component.scss']
})
export class MatchedFaresComponent implements OnInit {
  public date: DateRange;

  activeRoute: string;
  links: Tab[] = [];
  fares: Fare[];
  faresByAlertRuleSet: FaresByRuleSet[];
  public isLoading;
  public price: any;
  origin: any;
  destination: any;
  isOnSale: number;
  activeFilter: string;

  constructor(private htService: HorizontalTabsService,
              private dataStoreService: DataStoreService,
              private userService: UserService,
              private dialog: MatDialog,
              private loadIndicatorService: LoadIndicatorService) { }

  ngOnInit() {
    this.activeRoute = '/private/my-fares/alerts/matched';
    this.links = this.htService.getAlertsLinks();
    this.loadFares();
  }
  onNewAlert(ev, id) {
    ev.preventDefault();
    const dialogRef = this.dialog.open(NewAlertComponent, {
      width: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadFares();
    });
  }

  loadFares() {
    this.loadIndicatorService.addLoading();
    this.isLoading = true;
    this.dataStoreService.withAuth(this.userService.login_token).findAll(Fare, {
      'filter': {
        'user': true
      },
      'include': 'AlertRuleSet'
    }).subscribe((document: JsonApiQueryData<Fare>) => {
      this.loadIndicatorService.removeLoading();
      this.isLoading = false;
      this.fares = document.getModels();
    });
  }

  onActiveFilterChange(filter) {
    this.activeFilter = filter.id;
  }
}
