import { DataStoreService } from "./data-store.service";
import { Profile } from "../_models/Profile";
import { UserService } from "./user.service";
import { Injectable } from "@angular/core";
import { isNull } from "util";
import { of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private  dataStoreService: DataStoreService, private userService: UserService) {}

  loadProfile(doPeek = true) {
    let peek = null;
    if (doPeek) {
      peek =  this.dataStoreService.withAuth(this.userService.login_token).peekRecord(Profile, this.userService.drupal_id);
    }
    let observable = null;
    if (isNull(peek)) {
      observable = this.dataStoreService.withAuth(this.userService.login_token).findRecord(Profile, this.userService.drupal_id);
    }
    else {
      observable = of(peek)
    }

    return observable
  }
}
