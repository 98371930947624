import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RadioOption } from '../../../_models/RadioOption';
import { MatRadioChange } from '@angular/material/radio';


@Component({
  selector: 'app-fare-table-sale-filter',
  templateUrl: './fare-table-sale-filter.component.html',
  styleUrls: ['./fare-table-sale-filter.component.scss']
})
export class FareTableSaleFilterComponent implements OnInit {
  @Output() isOnSaleChange = new EventEmitter<number>();

  public checked;

  public filterOpts: RadioOption[];
  public selectedFilterOpt: string;

  constructor() { }

  ngOnInit() {
    this.filterOpts = [
      { value: '1', label: 'Show only sale fares'},
      { value: '2', label: 'Show only always on fares'}
    ];
  }
  onFilterSelect(event: MatRadioChange) {
    this.selectedFilterOpt = event.value;
    this.isOnSaleChange.emit(Number(event.value));
  }

  onResetFilter() {
    this.ngOnInit();
    this.selectedFilterOpt = '';
    this.isOnSaleChange.emit(0);
  }
}
