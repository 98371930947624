import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';

@Component({
  selector: 'app-fare-filter-switcher',
  templateUrl: './filter-switcher.component.html',
  styleUrls: ['./filter-switcher.component.scss']
})

export class FilterSwitcherComponent implements OnInit {
  @Input() filterTypes: Array<Select2OptionData>;

  @Input() selectedFilter: any;
  @Output() selectedFilterChange = new EventEmitter<Select2OptionData>();

  public select2Opts: Options;

  public filterMarkup = '<i class="fa fa-filter" aria-hidden="true"></i> <strong>Filter by</strong> ';

  constructor() { }

  ngOnInit() {
    this.select2Opts = {
      width: '280px',
      minimumResultsForSearch: Infinity,
      placeholder: this.filterMarkup + this.filterTypes[0].text,
      escapeMarkup: markup => markup, // remove HTML stripping from placeholder, etc
      templateSelection: (state) => {
        return this.filterMarkup + state.text;
      },
    };
  }

  onValueChanged(val) {
    this.selectedFilter = val.data[0];
    this.selectedFilterChange.emit(val.data[0]);
  }
}
