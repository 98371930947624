import { Injectable } from '@angular/core';
import { Observable, of, Subscriber } from "rxjs";
import { UserStorage } from "../_models/userStorage";
import { DataStoreService } from "./data-store.service";
import { ProfileInterface } from "../_interfaces/ProfileInterface";
import { copyTo, UserStorageInterface } from "../_interfaces/userStorageInterfaces";
import { User } from "../_models/User";
import { isNull } from "util";

@Injectable({
  providedIn: 'root'
})
export class UserService implements UserStorageInterface {
  get fully_authenticated(): boolean {
    return this._fully_authenticated;
  }

  set fully_authenticated(value: boolean) {
    this._fully_authenticated = value;
  }
  get drupal_id(): string {
    return this._drupal_id;
  }

  set drupal_id(value: string) {
    this._drupal_id = value;
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }

  get logged_in(): boolean {
    return this._logged_in;
  }
  set logged_in(value: boolean) {
    this._logged_in = value;
  }

  get login_token(): string {
    return this._login_token;
  }
  set login_token(value: string) {
    this._login_token = value;
  }

  get profileObservable(): Observable<ProfileInterface> {
    return this._profileObservable;
  }
  get profile() {
    return this._profile;
  }

  set profile(value) {
    this._profile = value;
    this._subscribers.map(subscriber => {
      subscriber.next(this.profile);
    })
  }

  get access_token() {
    return this._access_token;
  }

  set access_token(value) {
    this._access_token = value;
  }

  private _localStorageKey: string = 'app_user';

  private _access_token: string;
  private _profile: ProfileInterface;
  private _login_token: string;
  private _logged_in: boolean;
  private _id: string;
  private _drupal_id: string;

  private _subscribers: Subscriber<ProfileInterface>[] = [];

  // changing the version will logout everyone
 public version: string = 'v1';

  private _profileObservable: Observable<ProfileInterface> = new Observable<ProfileInterface>(subscriber => {
    this._subscribers.push(subscriber);
  });

  constructor(private dataStoreService: DataStoreService) {
    this.loadFromLocal();
  }
  login(userStorage: UserStorageInterface) {
    if (!userStorage.login_token) {
      return false;
    }
    userStorage.version = this.version;
    userStorage.login_time = new Date();
    copyTo(userStorage, this);
    this.logged_in = true;
    return true;
  }
  logout() {
    this.id = null;
    this.drupal_id = null;
    this.login_token = null;
    this.profile = null;
    localStorage.removeItem(this._localStorageKey);
    this.logged_in = false;
  }
  save() {
    let userStorage = new UserStorage(this);
    userStorage.version = this.version;
    let userData = JSON.stringify(userStorage);
    localStorage.setItem(this._localStorageKey, userData)
  }
  loadFromLocal() {
    let userData = localStorage.getItem(this._localStorageKey);
    if (!userData) {
      return;
    }
    let userStorage: UserStorage = JSON.parse(userData);
    if (userStorage.version != this.version) {
      this.logout();
      return;
    }
    userStorage.login_time = new Date(userStorage.login_time);
    copyTo(userStorage, this);
    this.logged_in = true;
  }

  loadUserInfo(doPeek = true): Observable<User> {
    let peek = null;
    if (doPeek) {
      peek =  this.dataStoreService.withAuth(this.login_token).peekRecord(User, this.id);
    }
    let observable = null;
    if (isNull(peek)) {
      observable = this.dataStoreService.withAuth(this.login_token).findRecord(User, this.id);
      observable.subscribe((user: User) => {
        this.onChangeFuncs.forEach((func: Function) => {
          func(user);
        })
      },
      (error) => {
        if ("Unauthorized" == error) {
          this.logout();
        }
      })
    }
    else {
      observable = of(peek)
    }
    return observable;
  }

  private onChangeFuncs: Function[] = [];

  onUserInfoChange(func: Function) {
    return this.onChangeFuncs.push(func);
  }

  loginDestination(user: User) {
    if (!this.fully_authenticated) {
      return '/private/my-fares/profile/change-password';
    }

    if (!user.approved) {
      return 'private/waiting-for-approval';
    }

    if (this.fully_authenticated && user.approved) {
      const login_dest_url = localStorage.getItem('login_dest_url');
      if (login_dest_url) {
        localStorage.removeItem('login_dest_url');
        return login_dest_url;
      }
      return 'private/my-fares/profile';
    }

    return  'public/login'
  }

  private _fully_authenticated: boolean = false;
  login_time: Date;
}
