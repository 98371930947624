import { Component, Input, OnInit } from '@angular/core';
import { UserService } from "../_services/user.service";
import { User } from "../_models/User";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";


export interface Section {
  icon: SafeResourceUrl;
  title: string;
  route: string;
  activeRoutes: Array<string>;
}

@Component({
  selector: 'app-my-fares-user-menu',
  templateUrl: './my-fares-user-menu.component.html',
  styleUrls: ['./my-fares-user-menu.component.scss']
})
export class MyFaresUserMenuComponent implements OnInit {
  @Input() activeRoute: string;
  public currentRoute: string;
  links: Section[] = [
    {
      icon: this.sanitizer.bypassSecurityTrustResourceUrl(''),
      title: 'Logout',
      route: '/private/logout',
      activeRoutes: [
        '/private/logout'
      ]
    }
  ];

  constructor(private userService: UserService, public sanitizer: DomSanitizer) {
    this.userService.loadUserInfo().subscribe((user: User) => {
      this.updateMenu(user);
    });
    this.userService.onUserInfoChange(this.updateMenu.bind(this));
  }

  updateMenu(user: User) {
    if (!this.userService.fully_authenticated && user.approved) {
      this.links = [
        {
          icon: 'fa-lock',
          title: 'Change Password',
          route: '/private/my-fares/profile/change-password',
          activeRoutes: [
            '/private/logout'
          ]
        },
        {
          icon: 'fa-sign-out',
          title: 'Logout',
          route: '/private/logout',
          activeRoutes: [
            '/private/logout'
          ]
        }
      ];
    }
    else if (user.approved) {
      this.links = [
        {
          icon: 'fa-user',
          title: 'My Profile',
          route: '/private/my-fares/profile',
          activeRoutes: [
            '/private/my-fares/profile'
          ]
        },
        {
          icon: 'fa-bell',
          title: 'My Fare Alerts',
          route: '/private/my-fares/alerts/my-alerts',
          activeRoutes: [
            '/private/my-fares/alerts/my-alerts',
            '/private/my-fares/alerts/matched'
          ]
        },
        {
          icon: 'fa-tag',
          title: 'View Specials',
          route: '/private/my-fares/specials',
          activeRoutes: [
            '/private/my-fares/specials',
            '/private/my-fares/specials-details'
          ]
        },
        {
          icon: 'fa-info-circle',
          title: 'How To',
          route: '/private/howto',
          activeRoutes: [
            '/private/howto'
          ]
        },
        {
          icon: 'fa-envelope',
          title: 'Contact Us',
          route: '/private/contact',
          activeRoutes: [
            '/private/contact'
          ]
        },
        {
          icon: 'fa-asterisk',
          title: 'T&Cs',
          route: '/private/terms-and-conditions',
          activeRoutes: [
            '/private/terms-and-conditions'
          ]
        },
        {
          icon: 'fa-sign-out',
          title: 'Logout',
          route: '/private/logout',
          activeRoutes: [
            '/private/logout'
          ]
        }
      ];
    } else {
      this.links = [
        {
          icon: 'fa-check-square',
          title: 'Approval required',
          route: '/private/waiting-for-approval',
          activeRoutes: [
            '/private/waiting-for-approval'
          ]
        },
        {
          icon: 'fa-sign-out',
          title: 'Logout',
          route: '/private/logout',
          activeRoutes: [
            '/private/logout'
          ]
        }
      ];
    }
  }

  ngOnInit() {
  }

}
