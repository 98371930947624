import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public url = '';
  constructor(public http: HttpClient) {
  }
  get({endpoint, resource = ''}, params?: any, reqOpts?: any) {
    const url = resource ? resource : this.url;
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.append(k, params[k]);
        // reqOpts.params.set(k, params[k]);
      }
    }
    return this.http.get(url + '/' + endpoint, reqOpts);
  }

  post({endpoint, resource = ''}, body: any, reqOpts?: any) {
    const url = resource ? resource : this.url;
    return this.http.post(this.url + '/' + endpoint, body, reqOpts);
  }

  put({endpoint, resource = ''}, body: any, reqOpts?: any) {
    const url = resource ? resource : this.url;
    return this.http.put(url + '/' + endpoint, body, reqOpts);
  }

  delete({endpoint, resource = ''}, reqOpts?: any) {
    const url = resource ? resource : this.url;
    return this.http.delete(this.url + '/' + endpoint, reqOpts);
  }

  patch({endpoint, resource = ''}, body: any, reqOpts?: any) {
    const url = resource ? resource : this.url;
    return this.http.patch(url + '/' + endpoint, body, reqOpts);
  }

  getAuthHeader(loginToken) {
    if (!loginToken) {
      throw new Error("Missing login_token must login of this request");
    }
    return new HttpHeaders({'Authorization': 'Bearer ' + loginToken});
  }
}
