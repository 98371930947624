import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './notFound.component';
import { ProfileComponent } from './profile/profile.component';
import { SpecialsComponent } from './specials/specials.component';
import { SpecialsDetailsComponent } from './specials-details/specials-details.component';
import { HistoryComponent } from './alerts/history/history.component';
import { MatchedFaresComponent } from './alerts/matched-fares/matched-fares.component';
import { MyAlertsComponent } from './alerts/my-alerts/my-alerts.component';
import { NewAlertComponent } from './alerts/new-alert/new-alert.component';
import { RegisterComponent } from './register/register.component';
import { TwoColComponent } from './common/private/two-col/two-col.component';
import { SingleColComponent } from './common/public/single-col/single-col.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { LogoutComponent } from './logout/logout.component';
import { TermsAndConditionsComponent } from './register/terms-and-conditions/terms-and-conditions.component';
import { ForgotPasswordComponent } from "./user/forgot-password/forgot-password.component";
import { WaitingForApprovalComponent } from "./waiting-for-approval/waiting-for-approval.component";
import { PasswordResetConfirmComponent } from "./user/password-reset-confirm/password-reset-confirm.component";
import { ChangePasswordComponent } from "./user/change-password/change-password.component";
import { ApprovedGuard } from "./guards/auth/approved.guard";
import { ResetEmailSentComponent } from "./user/reset-email-sent/reset-email-sent.component";
import { FullyAuthenticatedGuard } from "./guards/auth/fully-authenticated.guard";
import { ContactComponent } from "./contact/contact.component";
import { DebugComponent } from "./debug/debug.component";
import { HowtoComponent } from "./howto/howto.component";
import { TermsComponent } from "./terms/terms.component";

const routes: Routes = [
  { path: '', redirectTo: '/public/login', pathMatch: 'full' },
  { path: 'private', component: TwoColComponent,  canActivate: [AuthGuard], children: [
      { path: 'my-fares/profile', canActivate: [ApprovedGuard, FullyAuthenticatedGuard], component: ProfileComponent  },
      { path: 'my-fares/profile/change-password', canActivate: [], component: ChangePasswordComponent },
      { path: 'my-fares/specials', canActivate: [ApprovedGuard, FullyAuthenticatedGuard], component: SpecialsComponent },
      { path: 'my-fares/specials-details', canActivate: [ApprovedGuard, FullyAuthenticatedGuard], component: SpecialsDetailsComponent },
      { path: 'my-fares/alerts/matched', canActivate: [ApprovedGuard, FullyAuthenticatedGuard], component: MatchedFaresComponent },
      { path: 'my-fares/alerts/my-alerts', canActivate: [ApprovedGuard, FullyAuthenticatedGuard], component: MyAlertsComponent },
      { path: 'my-fares/alerts/history', canActivate: [ApprovedGuard, FullyAuthenticatedGuard], component: HistoryComponent },
      { path: 'alerts/new-alert', canActivate: [ApprovedGuard, FullyAuthenticatedGuard], component: NewAlertComponent },
      { path: 'waiting-for-approval', component: WaitingForApprovalComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'howto', component: HowtoComponent },
      { path: 'terms-and-conditions', component: TermsComponent },
      { path: 'logout', component: LogoutComponent },
      { path: 'debug', component: DebugComponent },
    ]
  },
  { path: 'public', component: SingleColComponent, children: [
      { path: '', redirectTo: '/public/login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
      { path: 'password-reset', component: ForgotPasswordComponent },
      { path: 'password-reset/sent', component: ResetEmailSentComponent },
      { path: 'password-reset-confirm/:uid/:timestamp/:hash', component: PasswordResetConfirmComponent },
      { path: 'debug', component: DebugComponent },
    ]},
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
