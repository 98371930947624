import { Component, OnInit } from '@angular/core';
import { Tab } from '../../_interfaces/tab';
import { HorizontalTabsService } from '../../_services/horizontal-tabs.service';
import { UserService } from "../../_services/user.service";
import { User } from "../../_models/User";
import { Router } from "@angular/router";
import {Content} from "../../_models/Content";
import {isNull} from "util";
import {of} from "rxjs/index";
import {DataStoreService} from "../../_services/data-store.service";

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  public message: string;
  public activeTab = 'register';
  public links: Tab[];
  public activeRoute: string;
  public formErrors;
  public formNotSubmitted;
  public termsContent;

  constructor(private htService: HorizontalTabsService,
              private dataStore: DataStoreService,
              private  userService: UserService,
              private router: Router) {
  }

  ngOnInit() {
    this.activeRoute = '/public/register';
    this.links = this.htService.getUserLinks();
    this.formNotSubmitted = false;
    this.termsContent = '';

    this.formErrors = {
      termsConditions: false
    };

    let peek = this.dataStore.peekRecord(Content, 'terms_and_conditions');
    let observable = isNull(peek) || typeof peek == 'undefined' ? this.dataStore.findRecord(Content, 'terms_and_conditions') : of(peek);
    observable.subscribe((Content) => {
      this.termsContent = Content.value;
    });
  }


  monitorErrorKeys(fields) {
    this.formErrors.termsConditions = !fields.termsConditions;
  }
  isFormValidated() {
    const { termsConditions } = this.formErrors;
    return !termsConditions;
  }

  onSubmit(formData) {
    this.monitorErrorKeys(formData.value);
    const isFormValidated = this.isFormValidated();
    if (isFormValidated === false) {
      this.formNotSubmitted = true;
      return false;
    }
    this.formNotSubmitted = false;
    this.userService.loadUserInfo(false).subscribe((user: User) => {
      if (user.partner_platform_terms_and_conditions) {
        let command = this.userService.loginDestination(user);
        this.router.navigateByUrl(command);
        return;
      }
      user.partner_platform_terms_and_conditions = true;
      user.save().subscribe((user: User) => {
        let command = this.userService.loginDestination(user);
        this.router.navigateByUrl(command);
      });
    })
  }

}
