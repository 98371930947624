import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Fare } from '../../_models/Fare';
import {UserService} from '../user.service';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {ShareFareDialogComponent} from '../../dialogs/share-fare-dialog/share-fare-dialog.component';

@Injectable({
  providedIn: 'root'
})

export class ShareFareService {
  private fare: Fare;
  public deeplinkId!: string;
  public currentlyOpen: boolean = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private dialog: MatDialog) {
  this.deeplinkId = this.route.snapshot.queryParamMap.get('fare_id');
  }

  setFare(fare: Fare) {
    this.fare = fare;
  }

  openShareFareDialog(): void {
    try {
      // Don't stack multiple share windows onto themselves
      if (this.currentlyOpen) {
        return;
      }
      if (this.fare === undefined) {
        throw new Error('No fare set');
      }

      const shareImgUrl = environment.specials_url + '/share_image/' + this.fare.id;
      const shareUrl = environment.specials_url + this.fare.url + this.fare.id;
      this.userService.loadUserInfo().subscribe(user => {
        const tracking_id = user.tracking_id;
        const searchParams = new URLSearchParams();
        searchParams.append('share', this.fare.id);
        if (tracking_id) {
          searchParams.append('cmpid', tracking_id);
        }
        const { href } = new URL(this.fare.url + '?' + searchParams, environment.specials_url);
        const dialogRef = this.dialog.open(ShareFareDialogComponent, {
          width: '80%',
          data: {
            row: this.fare,
            image_url: shareImgUrl,
            share_url: href,
            description: this.fare.title,
          }
        });
        // Keep a track of when the dialog is closed
        dialogRef.afterClosed().subscribe(() => {
          this.currentlyOpen = false;
          this.updateDeeplinkedUrl(true);
        });
        this.currentlyOpen = true;
        this.updateDeeplinkedUrl();
      });
    } catch (err) {
      this.currentlyOpen = false;
      console.log(err);
    }
  }

  // Update the deeplink query parameter as users open share dialogs
  updateDeeplinkedUrl(clear = false) {
    let fare_id = this.fare.id;
    if (clear) {
      fare_id = null;
    }
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { 'fare_id': fare_id },
        queryParamsHandling: 'merge',
      }
    )
  }
}
