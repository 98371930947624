import { Component, OnInit } from '@angular/core';
import { NoticeMessage, NoticeType } from "../_models/NoticeMessage";

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss']
})
export class DebugComponent implements OnInit {

  public messages: NoticeMessage[] = [

  ]

  constructor() {
    for (let type of [NoticeType.success, NoticeType.notice, NoticeType.warning, NoticeType.error]) {
      this.messages.push(
        {
          noticeType: type,
          title: "Important message about your insurance",
          content: "Please note that you have an insurance product linked to this booking. <a>Please contact Cover-More Travel Insurance</a> on 1300 135 769 within Australia or 0800 50 925 within New Zealand to change/cancel your policy."
        } as NoticeMessage
      )
    }
    for (let type of [NoticeType.success, NoticeType.notice, NoticeType.warning, NoticeType.error]) {
      let instance = new NoticeMessage();
      instance.noticeType = type;
      instance.title = "Important message about your insurance";
      instance.content = "Please note that you have an insurance product linked to this booking. <a>Please contact Cover-More Travel Insurance</a> on 1300 135 769 within Australia or 0800 50 925 within New Zealand to change/cancel your policy.";
      instance.button = {
        text: 'A call to action',
        click: () => {
          alert('Clicked ' + type.toString());
        },
        required: false
      };
      this.messages.push(instance);
    }
    for (let type of [NoticeType.success, NoticeType.notice, NoticeType.warning, NoticeType.error]) {
      let instance = new NoticeMessage();
      instance.noticeType = type;
      instance.title = "Important message about your insurance";
      instance.content = "Please note that you have an insurance product linked to this booking. <a>Please contact Cover-More Travel Insurance</a> on 1300 135 769 within Australia or 0800 50 925 within New Zealand to change/cancel your policy.";
      instance.button = {
        text: 'A call to action',
        click: () => {
          alert('Clicked ' + type.toString());
        },
        required: true
      };
      this.messages.push(instance);
    }
  }

  ngOnInit() {
  }

}
