import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { UserService } from "../../_services/user.service";
import { Observable, of } from "rxjs";
import { User } from "../../_models/User";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ApprovedGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {
  }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.userService.login_token) {
      return false;
    }
    return this.userService.loadUserInfo()
      .pipe(map( (user: User, idx: number) => {
        if (user.approved) {
          return true;
        } else {
          this.router.navigate(['/private/waiting-for-approval']);
          return false;
        }
      }))
      .pipe(catchError((val) => {
        this.router.navigate(['/public/login']);
        return of(false);
      }));
  }
}
