import * as $ from 'jquery';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';
import 'daterangepicker';
import { Component, DoCheck, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { DestinationsService, LocationAnywhere } from '../../_services/destinations/destinations.service';
import { DataStoreService } from '../../_services/data-store.service';
import { AlertRuleSet } from '../../_models/AlertRuleSet';
import { FaresService } from '../../_services/fares/fares.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { UserService } from '../../_services/user.service';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import * as Fuse from 'fuse.js';
import { DateRangePickerFixes } from '../../_util/DateRangePickerFixes';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Fare } from '../../_models/Fare';
import { JsonApiQueryData } from 'angular2-jsonapi';
import { NgForm } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { location } from "../../_models/Location";

const moment2 = extendMoment(moment);

@Component({
  selector: 'app-new-alert',
  templateUrl: './new-alert.component.html',
  styleUrls: ['./new-alert.component.scss']
})
export class NewAlertComponent implements OnInit, DoCheck {

  @ViewChild('originAutoComplete', {static: false}) originAutoComplete: AutocompleteComponent;
  @ViewChild('destinationAutoComplete', {static: false}) destinationAutoComplete: AutocompleteComponent;
  @ViewChild('originStateAutoComplete', {static: false}) originStateAutoComplete: AutocompleteComponent;
  @ViewChild('destinationStateAutoComplete', {static: false}) destinationStateAutoComplete: AutocompleteComponent;
  @ViewChild('dateRange', {static: false}) dateRange: ElementRef;
  @ViewChild('mobileFromDateRef', {static: false}) mobileFromDateRef: ElementRef;
  @ViewChild('mobileToDateRef', {static: false}) mobileToDateRef: ElementRef;
  @ViewChild('f', {static: false}) f: NgForm;

  public titleData;
  public originData: location[];
  // public destData;
  public destDataByOrigin: Map<string, location[]> = new Map<string, location[]>();
  public mobileFromDate;
  public mobileToDate;

  public originKeyword = 'airport';
  public destKeyword = 'airport';
  public filteredFares: any;
  public dateRangePicker: any;
  public dateRangePickerObj: object;
  public originIsSelected: boolean;
  public destinationIsSelected: boolean;
  public newAlertTitle: string;

  public originStateData: location[];
  // public destStateData;
  public originStateKeyword = 'state';
  public destStateKeyword = 'state';
  public destStateValue = '';
  public originStateIsSelected: boolean;
  public destinationStateIsSelected: boolean;
  public stateWithAirports: any;
  private anywhereDestination: location = LocationAnywhere;

  public fares: Fare[] = [];
  public selectedOrigin: location;
  public selectedDestination: location;
  public flexibleTime: boolean;
  public travelDateRangeAnyRangeValue: string;
  public settings = {
    noDataMessage: 'No fares match the current criteria',
    columns: {
      title: {
        title: 'Title',
        filter: false
      },
      sale_start: {
        title: 'Sale Start',
        filter: false,
        valuePrepareFunction: (startDate) => {
          const formatted = this.datePipe.transform(this.dateFormat(startDate));
          return formatted;
        }
      },
      sale_end: {
        title: 'Sale End',
        filter: false,
        valuePrepareFunction: (endDate) => {
          const formatted = this.datePipe.transform(this.dateFormat(endDate));
          return formatted;
        }
      },
      from_price: {
        title: 'From Price',
        filter: false,
        valuePrepareFunction: (price) => {
          const raw = price;
          let formattedPrice;
          if (price !== null) {
            return formattedPrice = '$' + price;
          }
        }
      },
      origin_display: {
        filter: false,
        title: 'Origin'
      },
      destination_display: {
        filter: false,
        title: 'Destination'
      },
      travel_period_string: {
        title: 'Travel Periods',
        filter: false,
        sort: false
      }
    },
    actions: false,
  };

  public alertFormErrors;

  public formNotSubmitted = false; // set default to false
  public existingAlert: AlertRuleSet;

  public isLoading;

  constructor(
    public datePipe: DatePipe,
    private destService: DestinationsService,
    private faresService: FaresService,
    private dataStoreService: DataStoreService,
    private router: Router,
    private userService: UserService,
    private drPickerUtil: DateRangePickerFixes,
    public dialogRef: MatDialogRef<NewAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private deviceDetect: DeviceDetectorService) {
  }

  async existingAlertClicked() {
    this.selectedOrigin = null;
    this.selectedDestination = null;

    this.selectedOrigin = this.destService.allOrigins.filter((origin) => {
      if (typeof this.existingAlert.originValue === 'undefined') {
        return origin.code === this.anywhereDestination.code;
      } else if (origin.type === 'state') {
        return this.existingAlert.originValue === origin.code;
      } else {
        return this.existingAlert.originValue === origin.iataCode;
      }
    })[0];

    this.selectedDestination = this.destService.allDestinations.filter((dest: any) => {
      if (typeof this.existingAlert.destinationValue === 'undefined') {
        return dest.code === this.anywhereDestination.code;
      } else if (dest.type === 'state') {
        return this.existingAlert.destinationValue === dest.code;
      } else {
        return this.existingAlert.destinationValue === dest.iataCode;
      }
    })[0];

    if ( this.selectedOrigin ) {
      // Does float label stuff.
      setTimeout(() => {
        $(this.originAutoComplete.elementRef.nativeElement).find('input').focus();
      });
    }

    if ( this.selectedDestination ) {
      // Does float label stuff.
      setTimeout(() => {
        $(this.destinationAutoComplete.elementRef.nativeElement).find('input').focus();
      });
    }

    this.titleData = this.existingAlert.title;

    setTimeout(() => {
      // We trigger a click on the form to make the ng-autocomplete form control dirty, otherwise validation wont work if
      // value is prepopulated, since its 3rd party lib.
      $('body').trigger('click');

      // Date range picker may not be ready yet, so we put inside a setTimeout.
      // Reason of its early execution is async await doesnt really stop the execution, the variables used by the method are not initialized
      this.onFilterResult();
    });
  }
  disableDateInput() {
    $(this.mobileFromDateRef.nativeElement).attr('disabled', true);
    $(this.mobileToDateRef.nativeElement).attr('disabled', true);
  }
  enableDateInput() {
    $(this.mobileFromDateRef.nativeElement).removeAttr('disabled');
    $(this.mobileToDateRef.nativeElement).removeAttr('disabled');
  }
  unsetExistingAlert() {
    this.existingAlert = null;
  }
  getDateFromTs(ts) {
    return this.datePipe.transform(ts);
  }
  async ngOnInit() {
    if ( this.dialogData !== null && this.dialogData.alert !== null ) {
      this.existingAlert = this.dialogData.alert;
    }

    this.newAlertTitle = '';
    //this.unsetExistingAlert();
    this.isLoading = false;

    this.alertFormErrors = {
      destinationData: false,
      originData: false,
      title: false,
      mobileToDate: false,
      mobileFromDate: false
    };
    this.originIsSelected = false;
    this.destinationIsSelected = false;
    this.originStateIsSelected = false;
    this.destinationStateIsSelected = false;

    // We're not using filtering at the moment, disabling loading fares below to put less load on the browser.
    // this.faresService.getAllFares().subscribe((fares: Fare[]) => {
    //   fares.forEach(fare => {
    //     fare.travel_period_string = fare.travel_periods.reduce((acc, curr, idx, arr) => {
    //       const comma = arr.length - 1 === idx ? '' : '\r\n';
    //       return `${acc} ${this.getDateFromTs(curr.start_date)} - ${this.getDateFromTs(curr.end_date)}${comma}`;
    //     }, '');
    //   });
    //   this.fares = fares;
    //   if (typeof this.existingAlert !== 'undefined') {
    //     this.onFilterResult();
    //   }
    // });

    this.travelDateRangeAnyRangeValue = 'Any Travel Period';

    this.stateWithAirports = await this.faresService.getAirports().toPromise();
    if (this.destService.allOrigins && this.destService.allOrigins.length > 0) {
      this.originData = this.destService.allOrigins;
      // this.destStateData = this.destService.allOrigins;
    } else {
      this.destService.getOrigins().subscribe((data) => {
        this.originData = data as unknown as location[];
        this.originStateData = this.destService.getStates();
        this.originData.push(...this.originStateData);
        this.originData.push(...this.destService.getHelpers());
      });
      this.originStateData = this.destService.getStates();
    }

    this.flexibleTime = true;
    this.disableDateInput();
    // $(this.dateRange.nativeElement).attr('disabled', true); // we disable daterange picker whenever flexible time is true;

    // An existing alert may have been passed in for editing.
    if (typeof this.existingAlert !== 'undefined') {
      this.existingAlertClicked();
    }

    this.initOriginDestinationBlurEvents();
  }

  initOriginDestinationBlurEvents() {
    try {
      $(this.originAutoComplete.elementRef.nativeElement).find('input').blur((ev) => {
        if (ev.delegateTarget.value === '') {
          this.selectedOrigin = this.anywhereDestination;
          this.originIsSelected = true;
        }
      });
    } catch (err) {
      console.log(err);
    }

    try {
      $(this.destinationAutoComplete.elementRef.nativeElement).find('input').blur((ev) => {
        if (ev.delegateTarget.value === '') {
          this.selectedDestination = this.anywhereDestination;
          this.destinationIsSelected = true;
        }
      });
    } catch (err) {
      console.log(err);
    }
  }
  onClearTravelPeriod() {
    this.dateRange.nativeElement.value = this.travelDateRangeAnyRangeValue;
    this.mobileFromDateRef.nativeElement.value = '';
    this.mobileToDateRef.nativeElement.value = '';
    this.onFilterResult();
  }

  initDatepicker() {
    let options: any = {};
    let setDefaultDates = false;
    options.autoUpdateInput = false;
    options.opens = 'center';
    if ( typeof this.existingAlert !== 'undefined' &&  this.existingAlert.alertRuleDateTimes.length) {
      options.startDate = moment.utc(this.existingAlert.alertRuleDateTimes[0].from_datetime, 'YYYY-MM-DD[T]HH:mm:ss').format('MM/DD/YYYY');
      options.endDate  = moment.utc(this.existingAlert.alertRuleDateTimes[0].to_datetime, 'YYYY-MM-DD[T]HH:mm:ss').format('MM/DD/YYYY');
      setDefaultDates = true;
    }

    const drp = $(this.dateRange.nativeElement).daterangepicker(options);

    if ( setDefaultDates ) {
      // Set the value of the date input field.
      const fromDate = moment.utc(this.existingAlert.alertRuleDateTimes[0].from_datetime, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY');
      const toDate = moment.utc(this.existingAlert.alertRuleDateTimes[0].to_datetime, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY');
      this.dateRange.nativeElement.value =   fromDate + ' - ' + toDate;

      // tslint:disable-next-line:max-line-length
      this.mobileFromDateRef.nativeElement.value =  moment(this.existingAlert.alertRuleDateTimes[0].from_datetime, 'YYYY-MM-DD').format('YYYY-MM-DD');
      // tslint:disable-next-line:max-line-length
      this.mobileToDateRef.nativeElement.value =  moment(this.existingAlert.alertRuleDateTimes[0].to_datetime, 'YYYY-MM-DD').format('YYYY-MM-DD');
      this.enableDateInput();
    }

    this.setDateRangePickerObject(drp.data('daterangepicker'));
    $(this.dateRange.nativeElement).on('apply.daterangepicker', (ev, picker) => {
      let pickerObj;
      if (picker) {
        pickerObj = picker;
        this.mobileFromDateRef.nativeElement.value = pickerObj.startDate.format('YYYY-MM-DD');
        this.mobileToDateRef.nativeElement.value = pickerObj.endDate.format('YYYY-MM-DD');
        this.enableDateInput();
      } else {
        pickerObj = {
          startDate: this.mobileFromDate,
          endDate: this.mobileToDate
        };
      }
      this.setDateRangePickerObject(pickerObj);
      $(this.dateRange.nativeElement).val(pickerObj.startDate.format('DD/MM/YYYY') + ' - ' + pickerObj.endDate.format('DD/MM/YYYY'));
      this.onFilterResult();
    });

    $(this.dateRange.nativeElement).on('showCalendar.daterangepicker', (ev, picker) => {
      const $drPicker = $('.daterangepicker');
      this.drPickerUtil.fixCalendarRows($drPicker);
    });
  }

  onMobileFromDate(frmDate, toDate) {
    toDate.value = '';
    toDate.focus();
    this.dateRange.nativeElement.value = this.travelDateRangeAnyRangeValue;
  }
  onMobileToDate(toDate, frmDate) {
    const formattedToDate = this.dateFormat(toDate.value, 'MM/DD/YYYY');
    const formattedFromDate = this.dateFormat(frmDate.value , 'MM/DD/YYYY');

    this.mobileFromDate = moment(frmDate.value);
    this.mobileToDate = moment(toDate.value);

    const diff = this.mobileToDate.diff(this.mobileFromDate, 'days');
    if ( diff < 1 ) {
      toDate.value = '';
      return false;
    }
    if (toDate.value && frmDate.value) {
      $(this.dateRange.nativeElement).data('daterangepicker').setStartDate(formattedFromDate);  // setStartDate expects mm/dd/yyyy
      $(this.dateRange.nativeElement).data('daterangepicker').setEndDate(formattedToDate);
      $(this.dateRange.nativeElement).trigger('apply.daterangepicker');
    } else {
      this.dateRange.nativeElement.value = this.travelDateRangeAnyRangeValue;
    }
  }

  // ng-autocomplete doesn't offer an ID input. The below mimics a label click with "for=id" specified.
  onOriginLabelClick() {
    this.originAutoComplete.focus();
  }

  // ng-autocomplete doesn't offer an ID input. The below mimics a label click with "for=id" specified.
  onDestinationLabelClick() {
    this.destinationAutoComplete.focus();
  }

  checkShouldCalendarRow($row) {
    let shouldShow = false;
    $row.find('td').each(function () {
      if (!$(this).hasClass('off')) {
        // If even 1 table cell is visible, we must show the row.
        shouldShow = true;
        return;
      }
    });
    return shouldShow;
  }

  originCleared(ev) {
    const currentSelectedOrigin = this.selectedOrigin;
    this.originIsSelected = false;
    this.selectedOrigin = null;
    // If the selected origin is "anywhere", no need to clear the destination as the destintaion values will not change.
    if (this.anywhereDestination.airport !== currentSelectedOrigin.airport) {
      // Check if the destination value has an iata, if so we have to clear it as origins determine which airports can be selected
      if (this.destinationIsSelected && this.selectedDestination.iataCode !== '') {
        this.selectedDestination = null;
      }

      this.filteredFares = [];
      this.destinationCleared('', false);
      this.onFilterResult();
    }

    // Set focus back on the input field.
    setTimeout(() => {
      $(this.originAutoComplete.elementRef.nativeElement).find('input').focus();
    });
  }

  calcIsFlexibleTime() {
    if (this.dateRange.nativeElement.value === this.travelDateRangeAnyRangeValue) {
      this.flexibleTime = true;
    } else {
      this.flexibleTime = false;
    }
  }

  monitorErrorKeys(fields) {
    Object.keys(fields).forEach(key => {
      if (fields[key] === undefined) {
        fields[key] = '';
      }
    });

    this.alertFormErrors.destinationData = this.originIsSelected || this.destinationIsSelected;
    this.alertFormErrors.originData = this.originIsSelected || this.destinationIsSelected;
  }
  isMobileFieldsValidated() {
    if (this.flexibleTime) {
      return true;
    } else {
      const frmDate = this.mobileFromDateRef.nativeElement.value;
      const toDate = this.mobileToDateRef.nativeElement.value;
      this.alertFormErrors.mobileFromDate = !!frmDate.value;
      this.alertFormErrors.mobileToDate = !!toDate.value;
      return !!frmDate && !!toDate;
    }
  }

  isAlertFormValidated() {
    const {destinationData, originData} = this.alertFormErrors;
    // return destinationData && originData;
    return destinationData || originData;
  }

  onSubmitAlertForm(f) {
    this.monitorErrorKeys(f.value);
    const isFormValidated = this.isAlertFormValidated();
    const isMobileFieldsValidated = this.isMobileFieldsValidated();
    if (isFormValidated === false ) {
      this.formNotSubmitted = true;
      return false;
    }
    if (isMobileFieldsValidated === false) {
      this.formNotSubmitted = true;
      return false;
    }
    this.isLoading = true;

    this.formNotSubmitted = false;
    this.calcIsFlexibleTime();

    let title: string;
    if (f.value.title) {
      title = f.value.title;
    } else {
      // tslint:disable-next-line:variable-name
      let o_title = '<Any>';
      if (f.value.originData && !this.destService.isAnywhere(f.value.originData) &&
        f.value.originData !== this.anywhereDestination.airport
        && f.value.originData.code) {
        o_title = f.value.originData.iataCode ? f.value.originData.iataCode : f.value.originData.code;
      }
      // tslint:disable-next-line:variable-name
      let d_title = '<Any>';
      if (f.value.destinationData && !this.destService.isAnywhere(f.value.destinationData) &&
        f.value.destinationData !== this.anywhereDestination.airport
        && f.value.destinationData.code) {
        d_title = f.value.destinationData.iataCode ? f.value.destinationData.iataCode : f.value.destinationData.code;
      }

      title = o_title + ' - ' + d_title;
    }

    const alertSetData = {
      title,
      created_date: new Date(),
      longevity: 5000,
      alertRuleStates: [],
      alertRuleIatas: [],
      alertRuleDateTimes: [],
      flexibleTime: this.flexibleTime
    };

    // Set conditional variables
    if (!this.flexibleTime) {
      alertSetData.alertRuleDateTimes = [{
        from_datetime: this.dateRangePicker.startDate.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        to_datetime: this.dateRangePicker.endDate.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
      }];
    }
    let objIata = {},
      objState = {};

    if (f.value.originData && !this.destService.isAnywhere(f.value.originData)) {
      if (f.value.originData.iataCode) {
        objIata = {from_iata: f.value.originData.iataCode};
      } else {
        objState = {from_state: f.value.originData.code};
      }
    }

    if (f.value.destinationData && !this.destService.isAnywhere(f.value.destinationData)) {
      if (f.value.destinationData.iataCode) {
        Object.assign(objIata, {to_iata: f.value.destinationData.iataCode});
      } else {
        Object.assign(objState, {to_state: f.value.destinationData.code});
      }
    }

    if (Object.keys(objIata).length > 0 && objIata.constructor === Object) {
      alertSetData.alertRuleIatas = [objIata];
    }

    if (Object.keys(objState).length > 0 && objState.constructor === Object) {
      alertSetData.alertRuleStates = [objState];
    }


    // Submit
    if (this.existingAlert) {
      // PATCH
      this.existingAlert.title = alertSetData.title;
      this.existingAlert.alertRuleIatas = alertSetData.alertRuleIatas;
      this.existingAlert.alertRuleStates = alertSetData.alertRuleStates;
      this.existingAlert.alertRuleDateTimes = alertSetData.alertRuleDateTimes;

      // Backend issue needs resolving here as JSON API only sends changed keys, at present seems backend expects all on patch.
      if( this.existingAlert.hasDirtyAttributes) {
        this.existingAlert.save().subscribe((data) => {
          f.reset();
        }, (err) => {
          this.isLoading = false;
          console.log(err);
        },
        () => {
          this.unsetExistingAlert();
          this.dialogRef.close();
        });
      } else {
        // Nothing to update.
        this.unsetExistingAlert();
        this.dialogRef.close();
      }
    } else {
      // POST
      const alertset = this.dataStoreService.withAuth(this.userService.login_token).createRecord(AlertRuleSet, alertSetData);
      alertset.save().subscribe((data) => {
        f.reset();
        this.originIsSelected = false;
        this.destinationIsSelected = false;
        this.filteredFares = [];
      }, (err) => {
        this.isLoading = false;
        console.log(err);
      }, () => {
        this.newAlertTitle = title;
      });
    }
    f.reset();
  }


  onCreateNewAlert() {
    // "newAlertTitle" is used on an ngIf directirve to render the form, we can simply set it back to an empty string
    // to show the form again.
    this.newAlertTitle = '';

    // We lose existing blur events on origin and destination when after an alert is submitted, re-initialise them.
    setTimeout(() => {
      this.initOriginDestinationBlurEvents();
    });
  }

  ngDoCheck() {
    // Initialise daterangepicker if the input is present, and daterangepicker has not been initialised.
    // Check if the input we will be using for date range picker is visible.
    if (typeof this.dateRange !== 'undefined') {
      // Check if daterangepicker has been initialised.
      const drp = $(this.dateRange.nativeElement).data('daterangepicker');
      if (typeof drp === 'undefined') {
        this.initDatepicker();
      }
    }
  }


  onCloseWindow() {
    this.dialogRef.close();
  }

  onOriginState(originAirportArr, faresObj) {
    let fares = faresObj.sale_items ? faresObj.sale_items : faresObj; // sale_items key seems to have been removed, cater for both.
    const options = {
      shouldSort: true,
      threshold: 0.0,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        'origin'
      ]
    };
    const fuse = new Fuse(fares, options);
    const filteredFares = [];
    originAirportArr.forEach((airportCode) => {
      const searchedItems = fuse.search(airportCode);
      if (searchedItems.length > 0) {
        filteredFares.push(...searchedItems);
      }
    });
    return filteredFares;
  }

  onOriginStateAndDestinationState(destinationAirportArr, filteredByOriginArr) {
    const fares = filteredByOriginArr;
    const options = {
      shouldSort: true,
      threshold: 0.0,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        'destination'
      ]
    };
    const fuse = new Fuse(fares, options);
    const filteredFares = [];
    destinationAirportArr.forEach((airportCode) => {
      const searchedItems = fuse.search(airportCode);
      if (searchedItems.length > 0) {
        filteredFares.push(...searchedItems);
      }
    });
    return filteredFares;
  }

  private setDestinationData(origin: location) {
    if (origin.type === 'iata') {
      this.destService.getDestinations(origin.iataCode).subscribe((data) => {
        let clone_data = data as unknown as location[];
        // this.destStateData = this.destService.getStates();
        clone_data.push(...this.destService.getStates());
        clone_data.push(...this.destService.getHelpers());
        this.destDataByOrigin.set(origin.iataCode, clone_data);
        this.getDestinations =  [{ airport: 'Trigger update' } as location];
      });
    }
  }


  public get getDestinations(): location[] {
    if (this.selectedOrigin && this.selectedOrigin.type === 'iata' && this.selectedOrigin.iataCode && this.destDataByOrigin.has(this.selectedOrigin.iataCode)) {
      return this.destDataByOrigin.get(this.selectedOrigin.iataCode);
    }
    else if (this.selectedOrigin) {
      return this.destService.allDestinations;
    }
    return [this.anywhereDestination];
  }

  public set getDestinations(val){

  }

  selectOriginEvent(item) {
    this.originIsSelected = true;
    const isAnywhere = this.destService.isAnywhere(item);
    let code = '';
    // We only have to update the destination list if there is a selected destination which has an IATA OR the selected
    // origin contains is not a state. For states we render all destinations (the default)
    if (isAnywhere || (this.destinationIsSelected && this.selectedDestination.iataCode !== '') || (item && item.type !== 'state')) {
      this.selectedDestination = null;
      if (!isAnywhere) {
        this.selectedOrigin = item;
      }
      this.setDestinationData(this.selectedOrigin);
    } else {
      this.selectedOrigin = item;
    }
    this.onFilterResult();
    this.autocompleteIE11CloseFix('origin');
  }

  onChangeOriginSearch(val: string) {
    this.originIsSelected = true;
  }

  onOriginFocused(e) {
    this.originIsSelected = true;
  }

  getFares() {
    this.dataStoreService.findAll(Fare).subscribe((document: JsonApiQueryData<Fare>) => {
      return document.getModels();
    });
  }

  selectDestEvent(item) {
    this.destinationIsSelected = true;

    if (item && item.type === 'state') {
      item.iataCode = '';
    }
    this.selectedDestination = item;
    this.onFilterResult();
    this.autocompleteIE11CloseFix('destination');

  }

  onChangeDestSearch(val: string) {
    this.destinationIsSelected = true;
  }

  onDestFocused(e) {
    this.destinationIsSelected = true;
  }

  destinationCleared(ev, setFocusBack) {
    this.destinationIsSelected = false;
    this.selectedDestination = null;
    this.onFilterResult();
    if (setFocusBack) {
      // Set focus back on the input field.
      setTimeout(() => {
        $(this.destinationAutoComplete.elementRef.nativeElement).find('input').focus();
      });
    }

  }

  setDateRangePickerObject(picker) {
    this.dateRangePickerObj = picker;
    this.dateRangePicker = {
      startDate: picker.startDate,
      endDate: picker.endDate
    };
    const {startDate, endDate} = this.dateRangePicker;
    this.dateRangePicker.dateArr = this.getDateArr(startDate, endDate);
  }

  getDateArr(start, end) {
    const arr = [];
    const dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt));
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }

  onChangeFlexibleTime() {
    if (this.flexibleTime) {
     // $(this.dateRange.nativeElement).attr('disabled', true);
      this.disableDateInput();
      this.dateRange.nativeElement.value = this.travelDateRangeAnyRangeValue;
      this.mobileFromDateRef.nativeElement.value = '';
      this.mobileToDateRef.nativeElement.value = '';
    } else {
     // $(this.dateRange.nativeElement).removeAttr('disabled');
      this.enableDateInput();
    }
    this.onFilterResult();
  }

  private human_date_format = 'MMM D, YYYY';

  onFilterResult() {
    // This method handles filtering and is called on most input changes. For now filtering has been removed so disable
    // the below logic for performance reasons.
    this.filteredFares = [];
    return;


    this.calcIsFlexibleTime();
    const fares = this.fares;
    let originData = this.selectedOrigin;
    let destinationData = this.selectedDestination;

    let filteredArray;
    // usual code
    if (!originData) {
      originData = this.anywhereDestination;
    } else if (this.destService.isAnywhere(originData)) {
      originData = this.anywhereDestination;
    }
    if (!destinationData) {
      destinationData = this.anywhereDestination;
    } else if (this.destService.isAnywhere(destinationData)) {
      destinationData = this.anywhereDestination;
    }

    if (this.destService.isAnywhere(originData) && this.destService.isAnywhere(destinationData)) {
      filteredArray = fares;
    } else if (originData.type === 'state' && this.destService.isAnywhere(destinationData)) {
      filteredArray = this.onOriginState(this.stateWithAirports[originData.code], fares);
    } else if (originData.type === 'state' && destinationData.type === 'state') {
      // The filteredOriginState variable is used to house filtered origin states, we then use that array to filter
      // against the destination iata codes.
      const filteredOriginState = this.onOriginState(this.stateWithAirports[originData.code], fares);
      filteredArray = this.onOriginStateAndDestinationState(this.stateWithAirports[destinationData.code], filteredOriginState);
    } else if (originData.type === 'state' && destinationData.iataCode && destinationData.iataCode.length > 1) {
      // The filteredOriginState variable is used to house filtered origin states, we then use that array to filter
      // against the destination iata codes.
      const filteredOriginState = this.onOriginState(this.stateWithAirports[originData.code], fares);
      const destinationAirportArr = [destinationData.iataCode];
      filteredArray = this.onOriginStateAndDestinationState(destinationAirportArr, filteredOriginState);
    } else if (originData.iataCode && originData.iataCode.length > 1 && destinationData.type === 'state') {
      const filteredByOrigin = this.onOriginState([originData.iataCode], fares);
      filteredArray = this.onOriginStateAndDestinationState(this.stateWithAirports[destinationData.code], filteredByOrigin);
    } else if (this.destService.isAnywhere(originData) && destinationData.type === 'state') {
      // using existing fn onOriginStateAndDestinationState, and passing fares as it is, without filtering it based on origin as its null
      // no need to create new fn onDestinationState
      filteredArray = this.onOriginStateAndDestinationState(this.stateWithAirports[destinationData.code], fares);
    } else if (this.destService.isAnywhere(originData) && (destinationData.iataCode && destinationData.iataCode.length > 1)) {
      const destinationAirportArr = [destinationData.iataCode];
      filteredArray = this.onOriginStateAndDestinationState(destinationAirportArr, fares);
    } else {
      filteredArray = fares.filter((saleItem) => {
        if (originData.iataCode === saleItem.origin && this.destService.isAnywhere(destinationData)) {
          return true;
        } else if (originData.iataCode === saleItem.origin && destinationData.iataCode === saleItem.destination) {
          return true;
        }
      });
    }

    if (!this.flexibleTime && this.dateRangePicker) {
      filteredArray = filteredArray.filter((fare) => {
        const dateArr = fare.travel_period_string.split('\r\n');
        const datesArrGroup = dateArr.map((dateRange) => {
          const dateRangeArr = dateRange.split('-');
          return {
            startDate: moment.utc(dateRangeArr[0].trim(), this.human_date_format),
            endDate: moment.utc(dateRangeArr[1].trim(), this.human_date_format),
            range: moment2.range(moment.utc(dateRangeArr[0].trim(), this.human_date_format), moment.utc(dateRangeArr[1].trim(), this.human_date_format))
          };
        });
        const dateRangeStart = this.dateRangePicker.startDate;
        const dateRangeEnd = this.dateRangePicker.endDate;

        const userRange = moment2.range(moment.utc(dateRangeStart), moment.utc(dateRangeEnd));
        const isSelectedDateRangeExistsWithinSalePeriod = datesArrGroup.some((dateRange) => {
          return dateRange.range.overlaps(userRange) || userRange.overlaps(dateRange.range);
        });
        if (isSelectedDateRangeExistsWithinSalePeriod) {
         return fare;
        }
      });
    }

    if (filteredArray.length > 5) {
      filteredArray = filteredArray.slice(0, 5);
    }
    this.filteredFares = filteredArray;
  }

  dateFormat(dateString, format = 'MM/DD/YYYY') {
    return moment(dateString).format(format);
  }
  // When component is destroyed
  async ngOnDestroy() {
    // Destroy date picker HTML
    this.drPickerUtil.destroyDrPicker(this.dateRangePickerObj);
  }

  // For IE11, Although the UL inside suggestions-container is empty when the autocomplete is closed, the height is
  // retained resulting in an empty box below the input. This conditional hack tricks the browser to reset the height.
  autocompleteIE11CloseFix(port) {
    let autocompleteNativeEl;
    // We support up to IE11 however apply this to all versions of 11 or less.
    if (this.deviceDetect.browser === 'IE') {
      if (port === 'origin') {
        autocompleteNativeEl = this.originAutoComplete.elementRef.nativeElement;
      } else {
        autocompleteNativeEl = this.destinationAutoComplete.elementRef.nativeElement;
      }

      if (autocompleteNativeEl !== undefined) {
        const listContainer = autocompleteNativeEl.querySelectorAll('.suggestions-container.is-visible ul')
        // Set the height to 1 (0 breaks populating the value), then shortly after set it back to auto which fixes this issue. We set it back to auto
        // after 1 second in preperation of the next open.
        listContainer.forEach((el, index) => {
          el.style.height = '1px';
          setTimeout(() => {
            el.style.height = 'auto';
          }, 1000);
        });
      }
    }
  }
}
