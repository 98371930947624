import { copyTo, UserStorageInterface } from "../_interfaces/userStorageInterfaces";

export class UserStorage implements UserStorageInterface {
  version: string;
  constructor(other: UserStorageInterface) {
    copyTo(other, this)
  }
  id: string;
  drupal_id: string;
  login_token: string;
  fully_authenticated: boolean;
  login_time: Date;
}



