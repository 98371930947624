export interface UserStorageInterface {
  version: string;
  login_time: Date;
  id: string;
  drupal_id: string;
  login_token: string;
  fully_authenticated: boolean;
}


export function copyTo(from: UserStorageInterface, to: UserStorageInterface) {
  Object.assign(to, extractUserStorageInterface(from));
}

const extractUserStorageInterface = extract<UserStorageInterface>({
  fully_authenticated: true,
  drupal_id: true, id: true, login_token: true, login_time: null, version: null
});

function extract<T>(properties: Record<keyof T, true>){
  return function<TActual extends T>(value: TActual){
    let result = {} as T;
    for (const property of Object.keys(properties) as Array<keyof T>) {
      result[property] = value[property];
    }
    return result;
  }
}
