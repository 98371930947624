import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Message } from "../_interfaces/Message";
import { Injectable } from "@angular/core";
import { LoginMessage } from "../_interfaces/LoginMessage";

@Injectable({
  providedIn: 'root'
})
export class PasswordResetService {
  private host:string = environment.backend_url;

  constructor(private http: HttpClient) {
  }

  public reset_request(email) {
    let observable = this.http.post<Message>(`${this.host}/password/reset/request`, {
        email: email,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );
    return observable;
  }

  public confirm_reset(uid, timestamp, hash) {
    let observable = this.http.post<LoginMessage>(`${this.host}/password/reset/confirm/${uid}/${timestamp}/${hash}`, {}, {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );
    return observable;
  }

  public change_password(password, loginToken) {
    let observable = this.http.post<Message>(`${this.host}/password/reset/change`, {
        password: password
      }, {
        headers: {
          'Authorization': 'Bearer ' + loginToken,
          'Content-Type': 'application/json',
        }
      }
    );
    return observable;
  }
}
