import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { DestinationsService } from '../../../_services/destinations/destinations.service';
import * as $ from 'jquery';
import { Fare } from "../../../_models/Fare";

@Component({
  selector: 'app-fare-table-location-filter',
  templateUrl: './fare-table-location-filter.component.html',
  styleUrls: ['./fare-table-location-filter.component.scss']
})
export class FareTableLocationFilterComponent implements OnInit {
  @Input() source: Fare[];
  @Output() originChange = new EventEmitter();
  @Output() destinationChange = new EventEmitter();
  @ViewChild('originAutoComplete', {static: false}) originAutoComplete: AutocompleteComponent;
  @ViewChild('destinationAutoComplete', {static: false}) destinationAutoComplete: AutocompleteComponent;


  private sourceCopy;
  public originData;
  public destData;
  private destDataCopy;
  private originDataCopy;
  private anywhereOriginDestination;
  public originKeyword = 'airport';
  public destKeyword = 'airport';
  public originIsActive: boolean;
  public destinationIsActive: boolean;
  public defaultOrigin;
  public defaultDestination;
  public selectedOrigin;
  public selectedDestination;

  constructor(private destService: DestinationsService) { }

  ngOnInit() {
    this.originIsActive = false;
    this.destinationIsActive = false;

    this.anywhereOriginDestination = this.destService.getHelpers().filter((item) =>
      item.type === 'helper' && item.code === 'anywhere')[0];

    this.defaultOrigin = this.anywhereOriginDestination;
    this.defaultDestination = this.anywhereOriginDestination;
    this.selectedOrigin = this.defaultOrigin;
    this.selectedDestination = this.defaultDestination;

    this.sourceCopy = this.source.map( ({origin, origin_display, pos_region, destination, destination_display}) => {
      return {
        origin_display,
        origin,
        pos_region,
        destination_display,
        destination
      };
    });
    this.originData = this.source.map(({origin, origin_display, pos_region}) => {
      if (origin_display) {
        return {
          airport: origin_display,
          code: origin,
          iataCode: origin,
          regionCode: pos_region
        };
      }
    }).filter((el) => {
      return el !== undefined;
    }).sort((a, b) => a.airport.localeCompare(b.airport));

    this.destData = this.source.map(({destination, destination_display, pos_region}) => {
      if (destination_display) {
        return {
          airport: destination_display,
          code: destination,
          iataCode: destination,
          regionCode: pos_region
        };
      }
    }).filter((el) => {
      return el !== undefined;
    }).sort((a, b) => a.airport.localeCompare(b.airport));

    this.originData = this.getUnique(this.originData, 'iataCode');
    this.destData = this.getUnique(this.destData, 'iataCode');
    this.destDataCopy = JSON.parse(JSON.stringify(this.destData));
    this.originDataCopy = JSON.parse(JSON.stringify(this.originData));
    // ng-autocomplete doesn't have a blur listener, adding a custom one.
    // Use setTimeout as the autocomplete isn't ready yet.
    setTimeout(() => {
      try {
        $(this.originAutoComplete.elementRef.nativeElement).find('input').blur((ev) => {
          this.onOriginBlur(ev);
        });
      } catch (err) {
        console.log(err);
      }

      try {
        $(this.destinationAutoComplete.elementRef.nativeElement).find('input').blur((ev) => {
          this.onDestinationBlur(ev);
        });
      } catch (err) {
        console.log(err);
      }
    });
  }


  onOriginBlur(ev) {
    try {
      if (ev.delegateTarget.value === '') {
        this.originIsActive = false;
      }
    } catch (err) {
      console.log(err);
    }
  }

  onDestinationBlur(ev) {
    try {
      if (ev.delegateTarget.value === '') {
        this.destinationIsActive = false;
      }
    } catch (err) {
      console.log(err);
    }
  }

  getUnique(arr, comp) {
    return arr.map(e => e[comp]).map((e, i, final) => final.indexOf(e) === i && i).filter(e => arr[e]).map( e => arr[e]);
  }

  getFilteredDestintation(origin, source) {
    const filteredSource = source.filter((fare) => {
      return fare.origin === origin.iataCode;
    });
    const newDest = filteredSource.map(({destination, destination_display, pos_region}) => {
      return {
        airport: destination_display,
        code: destination,
        iataCode: destination,
        regionCode: pos_region
      };
    });
    return this.getUnique(newDest, 'iataCode');
  }

  getFilteredOrigin(destination, source) {
    const filteredSource = source.filter((fare) => {
      return fare.destination === destination.iataCode;
    });
    const newDest = filteredSource.map(({origin, origin_display, pos_region}) => {
      return {
        airport: origin_display,
        code: origin,
        iataCode: origin,
        regionCode: pos_region
      };
    });
    return this.getUnique(newDest, 'iataCode');
  }

  selectOriginEvent(obj) {
    // Set destination data based on the selected origin.
    this.destData = this.getFilteredDestintation(obj, this.sourceCopy);

    // Set selected origin
    this.selectedOrigin = obj;

    // Emit event to parent component.
    this.originChange.emit(obj);
  }

  originCleared() {
    // Origin is no longer active.
    this.originIsActive = false;

    // Set default origin
    this.selectedOrigin = this.defaultOrigin;

    // Set destination data back to all available.
    this.destData = JSON.parse(JSON.stringify(this.destDataCopy));

    // Emit event to parent component.
    this.originChange.emit({});
  }

  selectDestEvent(obj) {
    // Set origin data based on the selected destination.
    this.originData = this.getFilteredOrigin(obj, this.sourceCopy);

    // Set selected destination
    this.selectedDestination = obj;

    // Emit event to parent component.
    this.destinationChange.emit(obj);
  }

  destinationCleared() {
    // Destination is no longer active.
    this.destinationIsActive = false;

    // Set default destination
    this.selectedDestination = this.defaultDestination;

    // Set origin data back to all available.
    this.originData = JSON.parse(JSON.stringify(this.originDataCopy));

    // Emit event to parent component.
    this.destinationChange.emit({});
  }

  destinationClosed() {
    // Destination is no longer active.
    this.destinationIsActive = false;
  }

  originClosed() {
    // Origin is no longer active.
    this.originIsActive = false;
  }

  originClicked(ev) {
    ev.preventDefault();
    this.originIsActive = true;
    this.originAutoComplete.focus();
  }

  destinationClicked(ev) {
    ev.preventDefault();
    this.destinationIsActive = true;
    this.destinationAutoComplete.focus();
  }

  onResetFilter() {
    this.originCleared();
    this.destinationCleared();
    this.originAutoComplete.clear();
    this.destinationAutoComplete.clear();
  }
}
