import { PropertyConverter } from "angular2-jsonapi";
import { UnixTimePropertyConverter } from "./UnixTimePropertyConverter";
import { isObject } from "util";

export class TravelPeriodPropertyConverter implements PropertyConverter {
  constructor(private unixTimePropertyConverter: UnixTimePropertyConverter) {
  }

  mask(value: any): any {
    return value.map((item) => {
      item.start_date = this.unixTimePropertyConverter.mask(item.start_date);
      item.end_date = this.unixTimePropertyConverter.mask(item.end_date);
      return item;
    });
  }

  unmask(value: any): any {
    return value.map((item) => {
      if (!isObject(item)) {
        return item;
      }
      item.start_date = this.unixTimePropertyConverter.unmask(item.start_date);
      item.end_date = this.unixTimePropertyConverter.unmask(item.end_date);
      return item;
    });
  }

}
