import { Injectable } from '@angular/core';
import { DatastoreConfig, JsonApiDatastore, JsonApiDatastoreConfig } from 'angular2-jsonapi';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertRuleSet } from '../_models/AlertRuleSet';
import { environment } from '../../environments/environment';
import { Fare } from '../_models/Fare';
import { User } from '../_models/User';
import { Profile } from '../_models/Profile';
import { Content } from '../_models/Content';
import { ContactSubmission } from '../_models/ContactSubmission';

const config: DatastoreConfig = {
  baseUrl: environment.backend_url + '/api',
  models: {
    "AlertRuleSet": AlertRuleSet,
    "Fare": Fare,
    "User": User,
    "Profile": Profile,
    "Content": Content,
    "ContactSubmission": ContactSubmission
  }
};

@Injectable({
  providedIn: 'root'
})
@JsonApiDatastoreConfig(config)
export class DataStoreService extends JsonApiDatastore {

  constructor(http: HttpClient) {
    super(http);
  }

  withAuth(loginToken) {
    if (!loginToken) {
      throw new Error("Missing login_token must login of this request");
    }
    this.headers = new HttpHeaders({'Authorization': 'Bearer ' + loginToken});
    return this;
  }
}
