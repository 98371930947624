import { EventEmitter } from "@angular/core";
import { TextContentInterface } from "../common/vaa-notice/vaa-notice.component";

export enum NoticeType {
  error = 'error',
  notice = 'notice',
  success = 'success',
  warning = 'warning'
}


export interface NoticeButtonCallback {
  text: string;
  required: boolean;
  click: Function;
}

export class NoticeMessage {
  show: boolean = true;
  title: string;
  content: string|TextContentInterface[];
  showChange: EventEmitter<boolean>;
  noticeType: NoticeType;
  button?: NoticeButtonCallback;
  eq(other: NoticeMessage) {
    let titleMatch = this.title === other.title;
    if (Array.isArray(this.content) && Array.isArray(other.content)) {
      if (this.content.length != other.content.length) {
        return false;
      }
      for (let i = 0; i < this.content.length; i++) {
        if (this.content[i].text != other.content[i].text) {
          return false;
        }
      }
      return titleMatch;
    }
    return titleMatch && this.content === other.content;

  }
}
