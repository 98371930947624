import { Attribute, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import {ContactSubmissionInterface} from '../_interfaces/ContactSubmissionInterface';

@JsonApiModelConfig({
  type: 'ContactSubmission'
})
export class ContactSubmission extends JsonApiModel implements ContactSubmissionInterface {
  @Attribute()
  uid: number;
  @Attribute()
  first_name: string;
  @Attribute()
  last_name: string;
  @Attribute()
  company: string;
  @Attribute()
  email: string;
  @Attribute()
  message: string;
}
