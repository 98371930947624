import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { UserService } from "../../_services/user.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FullyAuthenticatedGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {
  }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.userService.fully_authenticated) {
      this.router.navigate(['/private/my-fares/profile/change-password']);
    }
    return this.userService.fully_authenticated;
  }
}
