import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { JsonApiQueryData } from 'angular2-jsonapi';
import { LocalDataSource } from 'ng2-smart-table';
import { AlertRuleSet } from '../../_models/AlertRuleSet';
import { DataStoreService } from '../../_services/data-store.service';
import { HorizontalTabsService } from '../../_services/horizontal-tabs.service';
import { Tab } from '../../_interfaces/tab';
import { UserService } from '../../_services/user.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewAlertComponent } from '../new-alert/new-alert.component';
import { isArray } from "util";
import { LoadIndicatorService } from '../../_services/load-indicator.service';
import { DestinationsService } from '../../_services/destinations/destinations.service';
import { location } from "../../_models/Location";

export interface Fare {
  title: string;
  origin: string;
  destination: string;
  direction: string;
  price: number;
  saleStart: number;
  saleEnd: number;
}

@Component({
  templateUrl: './my-alerts.component.html',
  styleUrls: ['./my-alerts.component.scss']
})

export class MyAlertsComponent implements OnInit {

  activeRoute: string;
  links: Tab[] = [];

  public alertRuleSets: AlertRuleSet[];
  public editAlert: any;
  public dialogRef: MatDialogRef<NewAlertComponent>;
  public settings;
  public source: LocalDataSource;
  private allLocations: location[] = [];

  constructor(
    private dataStore: DataStoreService,
    private htService: HorizontalTabsService,
    private userService: UserService,
    public datePipe: DatePipe,
    private dialog: MatDialog,
    private loadIndicatorService: LoadIndicatorService,
    private destService: DestinationsService) {

  }
    public  isLoading: boolean;

  ngOnInit() {
    if (this.destService.allOrigins && this.destService.allOrigins.length > 0) {
      this.allLocations = this.destService.allOrigins;
    } else {
      this.destService.getOrigins().subscribe((data) => {
        this.allLocations = data as unknown as location[];
        const originStateData = this.destService.getStates();
        this.allLocations.push(...originStateData);
      });
    }

    this.loadAlerts();
    this.activeRoute = '/private/my-fares/alerts/my-alerts';
    this.links = this.htService.getAlertsLinks();
    this.settings = {
      noDataMessage: 'No current alerts',
      columns: {
        title:{
          title: 'Title',
          filter: false
        },
        originDisplay: {
          title: 'Origin',
          filter: false,
          valuePrepareFunction: (code) => {
            let formatted = code;
            if (code !== 'Empty') {
              for (const destination of this.allLocations) {
                if (destination.code === code) {
                  formatted = `${destination.airport} (${code})`;
                }
              }
            } else {
              formatted = 'Any';
            }
            return formatted;
          }
        },
        destinationDisplay: {
          title: 'Destination',
          filter: false,
          valuePrepareFunction: (code) => {
            let formatted = code;
            if (code !== 'Empty') {
              for (const destination of this.allLocations) {
                if (destination.code === code) {
                  formatted = `${destination.airport} (${code})`;
                }
              }
            } else {
              formatted = 'Any';
            }
            return formatted;
          }
        },
        fromDateTime: {
          title: 'Travel from',
          filter: false,
          valuePrepareFunction: (fromDateTime) => {
            if(fromDateTime.trim() === 'Empty') {
              return 'Any';
            }
            const formatted = moment.utc(fromDateTime, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').format('MMM D, YYYY');
            return formatted;
          }
        },
        toDateTime: {
          title: 'Travel to',
          filter: false,
          valuePrepareFunction: (toDateTime) => {
            if (toDateTime.trim() === 'Empty') {
              return 'Any';
            }
            const formatted = moment.utc(toDateTime, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').format('MMM D, YYYY');
            return formatted;
          }
        },
      },
      actions: {
        add: false,
        delete: false,
        edit: false,
        title: false,
        custom: [
          {
            name: 'customEdit',
            title: '<span class="alert-action edit-alert">Edit</span>'
          },
          {
            name: 'customDelete',
            title: '<span class="alert-action delete-alert">Delete</span>'
          }
        ],
        position: 'right'
      }
    };
  }
  onCustomEvent(event) {
    switch (event.action) {
      case 'customEdit':
        this.onEditAlert(event.data);
        break;
      case 'customDelete':
        this.onDeleteAlert(event.data.id);
        break;
    }
  }
  loadAlerts(peek: boolean = false){
    let res = null;
    if (peek) {
       res = this.dataStore.withAuth(this.userService.login_token).peekAll(AlertRuleSet);
    }
    if (isArray(res) && res.length > 0) {
      this.alertRuleSets = res;
    } else  {
      this.loadIndicatorService.addLoading();
      this.isLoading = true;
      this.dataStore.withAuth(this.userService.login_token).findAll(AlertRuleSet).subscribe(
        (alertRuleSets: JsonApiQueryData<AlertRuleSet>) => {
          this.alertRuleSets = alertRuleSets.getModels();
          this.loadIndicatorService.removeLoading();
          this.isLoading = false;
          this.source = new LocalDataSource(this.alertRuleSets);
        }
      );
    }
  }

  onDeleteAlert(id) {
    const deleter = this.dataStore.withAuth(this.userService.login_token).deleteRecord(AlertRuleSet, id);
    deleter.subscribe(value => {
      this.loadAlerts(false);
    });
  }


  onNewAlert() {
    const dialogRef = this.dialog.open(NewAlertComponent, {
      width: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadAlerts(false);
    });

  }

  onEditAlert(alert) {
    this.editAlert = alert;
    const dialogRef = this.dialog.open(NewAlertComponent, {
      width: '80%',
      data: { alert }
    });
    //dialogRef.componentInstance.setExistingAlert(alert);

    dialogRef.afterClosed().subscribe(result => {
      dialogRef.componentInstance.unsetExistingAlert();
      this.loadAlerts(false);
    });
  }

  dateFormat(dateString) {
    // datepipe.transform already converts it to locale
    return moment(dateString).valueOf();
  }
}
