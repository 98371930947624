import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {Fare} from '../../_models/Fare';
import { HttpClient } from '@angular/common/http';
import { FileSaverService } from 'ngx-filesaver';

export interface ShareFareDialogData {
  row: Fare;
  image_url: string;
  share_url: string;
  description: string;
}

@Component({
  selector: 'app-share-fare-dialog',
  templateUrl: './share-fare-dialog.component.html',
  styleUrls: ['./share-fare-dialog.component.scss']
})
export class ShareFareDialogComponent implements OnInit {

  private originalDescription: string;
  @ViewChild('f', {static: false}) shareForm: NgForm;

  constructor(
    public dialogRef: MatDialogRef<ShareFareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShareFareDialogData,
    private http: HttpClient,
    private fileSaver: FileSaverService) {}

  ngOnInit() {
    this.originalDescription = this.data.description;
  }

  onShareClick(): void {
    if (!this.shareForm.valid) {
      return;
    }

    let urlString = 'https://www.facebook.com/dialog/share?';

    // https://developers.facebook.com/docs/sharing/reference/share-dialog

    urlString += '&app_id=' + encodeURIComponent(environment.fb_app_id);
    urlString += '&display=' + encodeURIComponent('popup');
    urlString += '&href=' + encodeURIComponent(this.data.share_url);
    urlString += '&source=' + encodeURIComponent(this.data.image_url);
    urlString += '&description=' + encodeURIComponent(this.data.description);

    const width = 600;
    const height = 350;

    const facebookWindow = window.open(
      urlString,
      'Facebook',
      'toolbar=0,status=0,resizable=yes,width=' + width + ',height=' + height
      + ',top=' + (window.innerHeight - height) / 2 + ',left=' + (window.innerWidth - width) / 2
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
  }

  onDownloadClick(): void {
    const filename = this.originalDescription.replace(/\W+/g, '').toLowerCase();
    this.http.get(this.data.image_url, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe(res => {
      this.fileSaver.save(res.body, `${filename}.png`);
    });
  }

  onDownloadRawClick(): void {
    const filename = this.originalDescription.replace(/\W+/g, '').toLowerCase() + '-raw';
    this.http.get(this.data.image_url + '/raw', {
      observe: 'response',
      responseType: 'blob'
    }).subscribe(res => {
      this.fileSaver.save(res.body, `${filename}.png`);
    });
  }
}
