import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FareTableShareComponent } from './render-components/fare-table-share/fare-table-share.component';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { LocalDataSource } from 'ng2-smart-table';
import { FareTableOnSaleSoonComponent } from './render-components/fare-table-on-sale-soon/fare-table-on-sale-soon.component';
import { Fare } from '../_models/Fare';
import { DateRange } from '../_interfaces/DateRange';

const moment2 = extendMoment(moment);

export interface FilterOpt {
  id: string;
  text: string;
}


@Component({
  selector: 'app-fare-table',
  templateUrl: './fare-table.component.html',
  styleUrls: ['./fare-table.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class FareTableComponent implements OnInit {
  @Input() source: Fare[];
  tableSource: LocalDataSource;
  @Input() needFilters: boolean;
  @Input() showMatchedAlert: boolean;
  private _selectedDate: DateRange;
  private _price;
  private tablePagerPerPage = 10;

  @Input() date;
  @Input() set price(val) {
    this._price = val;
    if (typeof val !== 'undefined' && 'lowValue' in val && 'highValue' in val) {
      this.onPriceChange(val);
    }
  }
  get price() {
    return this._price;
  }

  private _origin;
  @Input() set origin(val) {
    this._origin = val;
    if (val) {
      this.onOriginChange(val);
    }
  }
  private _destination;
  @Input() set destination(val) {
    this._destination = val;
    if (val) {
      this.onDestinationChange(val);
    }
  }

  private _isOnSale: number;
  @Input() set isOnSale(val) {
    this._isOnSale = val;
    if (typeof val === "number") {
      this.onSaleChange(val);
    }
  }
  get isOnSale() {
    return this._isOnSale;
  }

  private _activeFilter: string;
  @Input() set activeFilter(val) {
    this._activeFilter = val;
    if (val) {
      this.onFilterChange();
    }
  }
  get activeFilter() {
    return this._activeFilter;
  }

  private _selectedAlert: number;
  @Input() set selectedAlert(val) {
    this._selectedAlert = val;
    this.onSelectedAlertChange(val);
  }
  get selectedAlert() {
    return this._selectedAlert;
  }


  @ViewChild('originAutoComplete', {static: false}) originAutoComplete: AutocompleteComponent;
  @ViewChild('destinationAutoComplete', {static: false}) destinationAutoComplete: AutocompleteComponent;

  public selectedFilter: FilterOpt;
  public filterTypes = [
    {
      id: 'date',
      text: 'Travel Date'
    },
    {
      id: 'price',
      text: 'Price'
    },
    {
      id: 'locations',
      text: 'Origin and Destination'
    },
    {
      id: 'sale',
      text: 'On sale'
    }
  ];
  public priceFilter;

  public settings = {
    pager: {
      display: true,
      perPage: this.tablePagerPerPage,
    },
    noDataMessage: 'No fares match the specified criteria',
    columns: {
      origin_display: {
        title: 'Origin',
        filter: false,
        sortDirection: 'asc',
      },
      destination_display: {
        title: 'Destination',
        filter: false,
      },
      travel_period_start: {
        title: 'Travel from',
        filter: false,
        valuePrepareFunction: (travelPeriodStartDate) => {
          if (travelPeriodStartDate !== null || travelPeriodStartDate) {
            const formatted = this.datePipe.transform(this.dateFormat(travelPeriodStartDate), 'd-MMM-yy', 'UTC+10');
            return formatted;
          }
        }
      },
      travel_period_end: {
        title: 'Travel to',
        filter: false,
        valuePrepareFunction: (travelPeriodEndDate) => {
          if (travelPeriodEndDate !== null || travelPeriodEndDate) {
            const formatted = this.datePipe.transform(this.dateFormat(travelPeriodEndDate), 'd-MMM-yy', 'UTC+10');
            return formatted;
          }
        }
      },
      travel_period_from_price: {
        title: 'Price',
        filter: false,
        valuePrepareFunction: (price) => {
          const raw = price;
          let formattedPrice;
          if (price !== null) {
            return formattedPrice = '$' + price;
          }
        }
      },
      travel_period_fare_brand: {
        title: 'Fare Brand',
        filter: false,
        valuePrepareFunction: (fareBrand) => {
          if (fareBrand !== null) {
            return fareBrand.charAt(0).toUpperCase() + fareBrand.slice(1);
          }
        }
      },
      sale_title: {
        title: 'Sale name',
        filter: false,
      },
      sale_start: {
        title: 'Sale start',
        filter: false,
        valuePrepareFunction: (startDate) => {
         if (startDate !== null || startDate) {
           const formatted = this.datePipe.transform(this.dateFormat(startDate), 'd/MM/yyyy', 'UTC+10');
           return formatted;
         }
        }
      },
      sale_end: {
        title: 'Sale end',
        filter: false,
        valuePrepareFunction: (endDate) => {
         if (endDate || endDate !== null) {
           const formatted = this.datePipe.transform(this.dateFormat(endDate), 'd/MM/yyyy', 'UTC+10');
           return formatted;
         }
        }
      },
      matched_alerts: {
        title: 'Matched Alerts',
        filter: false,
        hide: true,
        valuePrepareFunction: (ruleSets) => {
          // Ensure ruleSets is a valid object and not null or undefined
          if (ruleSets && typeof ruleSets === 'object') {
            return Object.values(ruleSets)  // Extract the values from the object
              .map((ruleSet: any) => ruleSet.title)  // Map over the array of values and get titles
              .join(', ');  // Join the titles with a comma
          }
          return '';  // Return empty string if ruleSets is not valid
        },
        compareFunction: (dir: number, a: any, b: any) => {
          const getTitleString = (ruleSets: any) => {
            if (ruleSets && typeof ruleSets === 'object') {
              return Object.values(ruleSets)
                .map((ruleSet: any) => ruleSet.title)
                .join(', ');
            }
            return '';
          };

          const titleA = getTitleString(a);
          const titleB = getTitleString(b);

          // Compare the concatenated title strings
          if (titleA < titleB) {
            return dir === 1 ? -1 : 1;
          } else if (titleA > titleB) {
            return dir === 1 ? 1 : -1;
          }
          return 0; // Equal
        }
      },
      on_sale_soon: {
        title: '',
        type: 'custom',
        renderComponent: FareTableOnSaleSoonComponent,
        filter: false,
        sort: false
      },
      share: {
        title: '',
        type: 'custom',
        renderComponent: FareTableShareComponent,
        filter: false,
        sort: false
      },
      always_on: {
        title: 'Always On',
        filter: false,
        sort: false,
        hide: true
      },
    },
    actions: false,
  };

  constructor(private route: ActivatedRoute,
              public datePipe: DatePipe) {
  }
  async ngOnInit() {
    this.selectedFilter = this.filterTypes[0];
    let clonedRows = [];
    // Filter out all fares with multiple travel periods. We'll assign fares with multiple travel periods to clonedRows.
    const source = this.source.filter((el, index) => {
      const travelPeriods = el.travel_periods;

      if (travelPeriods.length > 1) {
        for (let i in travelPeriods) {
          let clonedEl: any = {};
          // forEach, spread operator {...obj}, object.assign, Object.keys() etc
          // are not cloning all of the fare object's keys, forin is doing the trick.
          // tslint:disable-next-line:forin
          for (const key in el) {
            clonedEl[key] = el[key];
          }
          clonedEl.travel_period_start = travelPeriods[i].start_date;
          clonedEl.travel_period_end = travelPeriods[i].end_date;
          clonedRows.push({...clonedEl});
          clonedEl = {};
        }
      } else {
        // Only 1 travel period.
        return true;
      }
    });
    // Add fares that previously had multiple travel periods back to source with a single travel period only per Fare.
    source.push(...clonedRows);
    // Empty cloned rows after pushing to source.
    clonedRows = [];

    this.settings.columns.matched_alerts.hide = !this.showMatchedAlert;  // Show column
    this.tableSource = new LocalDataSource(source);
    this.handleDeeplinkedFares();
  }

  // Handle deeplinked fares
  handleDeeplinkedFares() {
    const deeplinkedId = this.route.snapshot.queryParamMap.get('fare_id');
    if (!deeplinkedId) {
      return;
    }
    // Listen for the first data update from ng2-smart-table
    const firstUpdateSub = this.tableSource.onChanged().subscribe(async (changes) => {
      const filteredItems = await this.tableSource.getFilteredAndSorted();
      const foundFare = filteredItems.findIndex((fare) => {
        return fare.id == deeplinkedId;
      });
      if (foundFare !== -1) {
        // The fare could be on any page of the filtered data
        // So find it and set the correct page
        const page = this.getPageForRowIndex(foundFare);
        this.tableSource.setPage(page);
      }
      // Once we have acted on the first data update: clear this subscriber
      firstUpdateSub.unsubscribe();
    });
  }

  getPageForRowIndex(index: number) {
    return Math.floor(index / this.tablePagerPerPage) + 1;
  }

  onFilterChange() {
    // Filters are independent of one another, when the filter type is changed
    // reset all existing filters so we can start fresh.
    this.tableSource.setFilter.apply(this.tableSource, []);
  }

  onSaleChange(show) {
    // Clear existing filters before applying new ones.
    this.tableSource.setFilter.apply(this.tableSource, []);
    switch (show) {
      case 1:
        // Apply on sale filter.
        this.onSaleFilter();
        break;
      case 2:
        // Apply always on filter.
         this.callFilter({
            query: 'true', // must be a string
            type: 'always_on'
          });
         break;
    }
  }

  onAlwaysOnChange(show) {
    if (Number(show) === 1) {
      this.callFilter({
        query: 'true', // must be a string
        type: 'always_on'
      });
    } else {
      // Clear the filter
      this.tableSource.setFilter.apply(this.tableSource, []);
    }
  }

  getDateFromTs(ts) {
    return this.datePipe.transform(ts, 'MMM d, yyyy', 'UTC+10');
  }

  callFilter({query, type}) {
    switch (type) {
      case 'price':
        this.tableSource.setFilter.apply(this.tableSource, [
          [
            ({
              field: 'travel_period_from_price', search: query,
              filter: (cell, priceRange) => {
                const priceRangeArr = priceRange.split('-');
                const minPrice = Number(priceRangeArr[0]);
                const maxPrice = Number(priceRangeArr[1]);
                const price = Number(cell);
                if (price >= minPrice && price <= maxPrice) {
                  return true;
                }
              }
            })
          ]
        ]);
        break;
      case 'origin':
        this.tableSource.setFilter.apply(this.tableSource, [
          [
            ({
              field: 'origin_display',
              search: query,
              filter: (cell, searchedString) => {
                if (searchedString.toLowerCase() === cell.toLowerCase())  {
                  return true;
                }
              }
            })
          ]
        ]);
        break;
      case 'destination':
        this.tableSource.setFilter.apply(this.tableSource, [
          [
            ({
              field: 'destination_display',
              search: query,
              filter: (cell, searchedString) => {
                if (searchedString.toLowerCase() === cell.toLowerCase())  {
                  return true;
                }
              }
            })
          ]
        ]);
        break;
      case 'always_on':
        this.tableSource.setFilter.apply(this.tableSource, [
          [
            ({
              field: 'always_on',
              search: query,
              filter: (cell, search) => {
                return cell.toString() === search;
              }
            })
          ]
        ]);
        break;
      case 'matched_alerts':
        if (query === null || query === '' || query === 0) {
          // Clear the filter
          this.tableSource.setFilter([]);
        } else {
          this.tableSource.setFilter.apply(this.tableSource, [
            [
              ({
                field: 'matched_alerts',
                search: query,
                filter: (cellValue, searchedID) => {
                  if (cellValue && typeof cellValue === 'object') {
                    // Check if any of the ruleSets match the searchedID
                    return Object.values(cellValue).some(
                      (ruleSet: any) => ruleSet.id == searchedID
                    );
                  }
                  return false; // No match
                }
              })
            ]
          ]);
        }
        break;
    }
  }

  onPriceChange(rangeVals) {
    const range = `${rangeVals.lowValue.toString()}-${rangeVals.highValue.toString()}`;
    this.callFilter({
      query: range,
      type: 'price'
    });
  }

  onOriginChange(obj) {
    const  text = ( typeof obj.airport === 'undefined' ) ? '' : obj.airport;
    this.callFilter({
      query: text,
      type: 'origin'
    });
  }

  onDestinationChange(obj) {
    const  text = ( typeof obj.airport === 'undefined' ) ? '' : obj.airport;
    this.callFilter({
      query: text,
      type: 'destination'
    });
  }

  onSelectedAlertChange(obj) {
    let id = null;
    if (obj) {
      id = ( typeof obj.id === 'undefined' ) ? null : obj.id;
    }
    if (this.tableSource)
    this.callFilter({
      query: id,
      type: 'matched_alerts'
    });
  }

  dateFormat(dateString) {
    return moment.utc(dateString).valueOf();
  }
  @Input()
  set selectedDate(dateObj) {
    this._selectedDate = dateObj;
    if (dateObj && dateObj.hasOwnProperty('startDate') && dateObj.hasOwnProperty('endDate')) {
      this.invokeDateRangeFilter(dateObj);
    }
  }

  get selectedDate() {
    return this._selectedDate;
  }

  invokeDateRangeFilter(obj) {
    const startDate = obj.startDate;
    const endDate = obj.endDate;

    // If both dates are null, clear the date filter.
    if (startDate === null && endDate === null) {
      this.tableSource.setFilter.apply(this.tableSource, []);
      return;
    }

    this.tableSource.setFilter.apply(this.tableSource,
      [
        [
          {
            field: 'travel_periods',
            search: 'n.a.', // we're not using this as the search string, we use "startDate" and "endDate" to search.
            // search is set to "n.a." as a string is required to trigger the filter function.
            filter: (travelPeriods, searchedQuery) => {
              if (travelPeriods.length) {
                const dateWithRange = {
                  startDate: moment(travelPeriods[0].start_date),
                  endDate: moment(travelPeriods[0].end_date),
                  range: moment2.range(moment(travelPeriods[0].start_date), moment(travelPeriods[0].end_date))
                };

                // We have both a start and end date filter value, so we check if if user entered range falls
                // within any period OR if period falls between user's entered range.
                const userRange = moment2.range(startDate, endDate);
                return  dateWithRange.range.overlaps(userRange) || userRange.overlaps( dateWithRange.range);
              }
            }
          }
        ]
      ]);
  }

  onSaleFilter() {
    const currentDate = moment();
    this.tableSource.setFilter.apply(this.tableSource, [
      [
        ({
          field: 'sale_start',
          search: currentDate ? currentDate.format() : '',
          filter: (cell, searchedString) => {
            // We have to compare the current date against both sale_start AND sale_end.
            // First we check the current date against the sale_start field.
            const cellDate = moment(cell);
            const searchedDate = moment(searchedString);
            if (cellDate  <= searchedDate ) {
              return true;
            }
          }
        }),
        ({
          field: 'sale_end',
          search:  currentDate ? currentDate.format() : '',
          filter: (cell, searchedString) => {
            // We have to compare the current date against both sale_start AND sale_end.
            // We've already checked sale_start, now we check the current date against the sale_end field.
            const cellDate = moment(cell);
            const searchedDate = moment(searchedString);
            if ( cellDate >= searchedDate ) {
              return true;
            }
          }
        }),
        ({
          field: 'always_on',
          search: 'false',
          filter: (cell, search) => {
            return cell.toString() === search;
          }
        })
      ]
    ]);
  }
}
