import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import * as moment from 'moment';
import { DateRangePickerFixes } from '../../../_util/DateRangePickerFixes';
import { DateRange } from '../../../_interfaces/DateRange';
import { RadioOption } from '../../../_models/RadioOption';

@Component({
  selector: 'app-fare-table-date-filter',
  templateUrl: './fare-table-date-filter.component.html',
  styleUrls: ['./fare-table-date-filter.component.scss']
})
export class FareTableDateFilterComponent implements OnInit {
  @Input() date: DateRange;
  @Output() dateChange = new EventEmitter<DateRange>();

  @ViewChild('dateRangeEl', {static: false}) dateRangeEl: ElementRef;

  private defaultDateText = 'Custom Date Range';
  public dateText = this.defaultDateText;
  public radioDays: string;
  public dateRangePickerObj;


  public dateOpts: RadioOption[];
  public selectedDateOpt: string;

  constructor(private drPickerUtil: DateRangePickerFixes) { }

  ngOnInit() {
    this.dateOpts = [
      { value: '1', label: 'Anytime'},
      { value: '2', label: 'This month'},
      { value: '3', label: 'Next 90 days'},
      { value: '4', label: 'Next 12 months'}
    ];
    this.selectedDateOpt = this.dateOpts[0].value;


    this.radioDays = '3';
    setTimeout(() => {
      this.initSaleStartDatepicker();
    });
  }

  initSaleStartDatepicker() {
    let drInit = $(this.dateRangeEl.nativeElement).daterangepicker({
      autoUpdateInput: false,
      singleDatePicker: false,
      autoApply: false
    });

    this.dateRangePickerObj = drInit.data('daterangepicker');
    this.dateRangePickerObj.container.addClass('fare-table-drp');

    $(this.dateRangeEl.nativeElement).on('apply.daterangepicker', (ev, picker) => {
      // $(this.dateElement.nativeElement).val(picker.startDate.format('DD/MM/YYYY'));
      this.doDateRangeFilter(picker);
    });

    $(this.dateRangeEl.nativeElement).on('showCalendar.daterangepicker', (ev, picker) => {
      const $drPicker = $('.daterangepicker');
      this.drPickerUtil.fixCalendarRows($drPicker);
    });
  }

  handleRadioDay($event) {
    let startDate = moment();
    let endDate;
    const val = $event.value;

    switch (val) {
      case '1':
        // Anytime
        endDate =  null;
        startDate =  null;
        break;
      case '2':
        // This month
        endDate = moment().endOf('month');
        break;
      case '3':
        // 90 days
        endDate =  moment().add(90, 'days');
        break;
      case '4':
        // 1 year
        endDate =  moment().add(1, 'years');
        break;
    }
    // Set date range text to default.
    this.dateText = this.defaultDateText;

    this.date = {startDate, endDate};
    // Emit event to parent component.
    this.dateChange.emit({startDate, endDate});
  }
  doDateRangeFilter(obj) {
    const startDate = obj.startDate;
    const endDate = obj.endDate;

    // Uncheck radio buttons.
    this.selectedDateOpt = null;

    // Set date range text.
    this.dateText = moment(obj.startDate).format('DD/MM/YYYY') + ' to ' +  moment(obj.endDate).format('DD/MM/YYYY') ;
    this.date = {startDate, endDate};
    // Emit event to parent component.s
    this.dateChange.emit({startDate, endDate});
  }

  async ngOnDestroy() {
    this.drPickerUtil.destroyDrPicker(this.dateRangePickerObj);
  }

  onResetFilter() {
    this.ngOnInit();
    const startDate = null;
    const endDate = null;
    this.dateText = this.defaultDateText;
    this.dateChange.emit({startDate, endDate});
  }
}
