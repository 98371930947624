// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // Replaced on docker start see Dockerfile
  // @ts-ignore
  production: "{APP_PRODUCTION}" === "true",
  backend_url: "{APP_BACKEND_URL}",
  vmail_url: "{APP_PREFERENCES_URL}",
  specials_url: "{APP_SPECIALS_URL}",
  fb_app_id: "{APP_FBAPPID}"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
