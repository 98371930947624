import { Component, OnInit } from '@angular/core';
import { HorizontalTabsService } from '../../_services/horizontal-tabs.service';
import {Tab} from '../../_interfaces/tab';

@Component({
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  activeRoute: string;
  links: Tab[] = [];

  constructor(private htService: HorizontalTabsService) {

  }

  ngOnInit() {
    this.activeRoute = '/private/my-fares/alerts/history';
    this.links = this.htService.getAlertsLinks();
  }

}
