import { Component, OnInit, Renderer2 } from '@angular/core';
import { DestinationsService } from "./_services/destinations/destinations.service";
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentRoute: string;

  constructor(private destService: DestinationsService,
              private renderer: Renderer2,
              private deviceDetect: DeviceDetectorService) {}

  async ngOnInit() {
    // Add the browser name to the body so we can apply browser specific css.
    this.renderer.addClass(document.body, this.deviceDetect.browser);

    await this.destService.init();
  }

  async ngAfterContentChecked() {
    window.PlaceholderShownPolyfill.updateAll();
  }
}
