import { Attribute, JsonApiModel, JsonApiModelConfig } from "angular2-jsonapi";
import { UserInterface } from "../_interfaces/UserInterface";

@JsonApiModelConfig({
  type: 'User'
})
export class User extends JsonApiModel implements UserInterface {
  @Attribute()
  email: string;
  @Attribute()
  drupalId: string;
  @Attribute()
  roles: Array<string>;
  @Attribute()
  black_listed: boolean;
  @Attribute()
  approved: boolean;
  @Attribute()
  company: string;
  @Attribute()
  partner_platform_terms_and_conditions: boolean;
  @Attribute()
  tracking_id?: string;
}
