import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ProfileService } from '../_services/profile.service';
import { LoadIndicatorService } from '../_services/load-indicator.service';
import { DataStoreService } from '../_services/data-store.service';
import { ContactSubmissionInterface } from '../_interfaces/ContactSubmissionInterface';
import { ContactSubmission } from '../_models/ContactSubmission';
import { User } from '../_models/User';
import { UserService } from '../_services/user.service';
import { finalize } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorResponse } from "angular2-jsonapi";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {
  public renderForm: boolean;
  public submission: ContactSubmissionInterface = {
    uid: 0,
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    message: '',
  };
  public backend_errors: backend_errors = {
    uid: '',
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    message: '',
  };

  constructor(private profileService: ProfileService,
              private userService: UserService,
              private dataStoreService: DataStoreService,
              private loadIndicatorService: LoadIndicatorService,
              private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.success) {
        this.renderForm = params.success === 'false';
      }
      else {
        this.renderForm = true;
      }
    });

    this.setUserDetails();
  }

  setUserDetails() {
    this.loadIndicatorService.addLoading();
    this.profileService.loadProfile().pipe(
      finalize(() => {
        this.loadIndicatorService.removeLoading();
      })
    ).subscribe((profile) => {
      // Setup default values for the submission
      for (const prop in this.submission) {
        if (this.submission.hasOwnProperty(prop) && profile[prop] !== undefined) {
          this.submission[prop] = profile[prop];
        }
      }
    });

    this.loadIndicatorService.addLoading();
    this.userService.loadUserInfo().pipe(
      finalize(() => {
        this.loadIndicatorService.removeLoading();
      })
    ).subscribe((user: User) => {
      this.submission.uid = Number(user.id);
      this.submission.company = user.company;
    });
  }

  onUserFormSubmit(form: NgForm) {
    if (form.invalid) {
      return;
    }

    // Reset user data in case something changed or was manipulated by user
    this.setUserDetails();

    // POST Data here.
    const submission = this.dataStoreService.withAuth(this.userService.login_token).createRecord(ContactSubmission, this.submission);

    submission.save().subscribe((data) => {
      this.router.navigate([], {queryParams: {
          success: true
      }})
    }, (errorResponse) => {
      if (errorResponse instanceof ErrorResponse) {
        // do something with errorResponse
        console.log(errorResponse.errors);
        errorResponse.errors.forEach((error) => {
          this.backend_errors[error.meta.property_path] = error.detail;
        })
      }
      this.router.navigate([], {queryParams: {
          success: false
        }})
    }, () => {
    });
  }
}

interface backend_errors {
  uid: string,
  first_name: string,
  last_name: string,
  company: string,
  email: string,
  message: string,
}
