import { Component, OnInit } from '@angular/core';
import { Content } from "../../../_models/Content";
import { ContentService } from "../../../_services/content.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-single-col',
  templateUrl: './single-col.component.html',
  styleUrls: ['./single-col.component.scss']
})
export class SingleColComponent implements OnInit {
  public homeTitle = '';
  public homeDescription = '';
  public displayDescription = true;

  constructor(private contentService: ContentService, private router: Router) { }

  ngOnInit() {
    this.contentService.getContent('home_title').subscribe((content: Content) => {
      if (content.value !== undefined) {
        this.homeTitle  = content.value;
      }
    });

    this.contentService.getContent('home_description').subscribe((content: Content) => {
      if (content.value !== undefined) {
        this.homeDescription = content.value;
      }
    });
  }

  toggleDefaultMessage() {
    var currentRoute = this.router.getCurrentNavigation().finalUrl.root.children.primary.segments.map(it => it.path).join('/');
    if (currentRoute == 'public/password-reset/sent') {
      this.displayDescription = false;
      return;
    }

    this.displayDescription = true;
  }
}
