import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { EndpointService } from '../api/endpoint.service';
import { Fare } from '../../_models/Fare';
import { JsonApiQueryData } from 'angular2-jsonapi';
import { of } from 'rxjs';
import { DataStoreService } from '../data-store.service';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root'
})
export class FaresService {
  private host : string = environment.backend_url;
  constructor(
    private api: ApiService,
    private dataStoreService: DataStoreService,
    private endpointService: EndpointService,
    private userService: UserService
  ) {
  }
  fareCache: Fare[] = [];

  getSalesEvents() {
     const route = this.endpointService.upcomingSaleEventsEndPoint;
     return this.api.get({
       resource: this.host,
       endpoint: route
     }, null, {headers: this.api.getAuthHeader(this.userService.login_token)});
  }


  getSpecials() {
    // tmp until CORS is enabled.
    return this.api.get({endpoint: 'assets/sales.json'});

    // return this.api.get({
    //   resource: 'https://specials-stage.vaa.atechnology.com.au',
    //   endpoint: 'feeds/all_sale_fares.json'}, {
    //   auth: 'ZmVlIWRzOmFjY19lc3Mh'
    // });
  }
  getAirports() {
    return this.api.get({endpoint: 'assets/state-iata.json'});
  }
  getAllFares() {
    if (this.fareCache.length > 0) {
      return of(this.fareCache);
    }
    else {
      return this.dataStoreService.findAll(Fare).pipe(map((queryData: JsonApiQueryData<Fare>, idx: number) => {
        this.fareCache = queryData.getModels();
        return this.fareCache;
      }));
    }
  }
}
