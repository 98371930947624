import { Injectable } from '@angular/core';
import {Tab} from '../_interfaces/tab';

@Injectable({
  providedIn: 'root'
})
export class HorizontalTabsService {
  userLinks: Tab[] = [
    {
      title: 'Login',
      route: '/public/login'
    },
    {
      title: 'Register',
      route: '/public/register'
    }
  ];

  alertsLinks: Tab[] = [
    {
      title: 'My alerts',
      route: '/private/my-fares/alerts/my-alerts'
    },
    {
      title: 'Matched fares',
      route: '/private/my-fares/alerts/matched'
    }
  ];


  specialsLinks: Tab[] = [
    {
      title: 'Fares',
      route: '/private/my-fares/specials'
    },
    {
      title: 'Campaign details',
      route: '/private/my-fares/specials-details'
    }

  ];

  constructor() { }

  getUserLinks() {
    return this.userLinks;
  }

  getAlertsLinks() {
    return this.alertsLinks;
  }
}
