import { PropertyConverter } from "angular2-jsonapi/dist/interfaces/property-converter.interface";
import { isDate, isString } from "util";


export class UnixTimePropertyConverter implements PropertyConverter {
  mask(unix_timestamp: any): any {
    if (isString(unix_timestamp)) {
      unix_timestamp = parseInt(unix_timestamp, 10);
    }
    return new Date(unix_timestamp * 1000)
  }

  unmask(date: any): any {
    if (isDate(date)) {
      return date.getTime() / 1000
    }
    return null;
  }

}
