import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataStoreService } from '../../../_services/data-store.service';
import { NgSelect2Component, Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { JsonApiQueryData } from 'angular2-jsonapi';
import { AlertRuleSet } from 'src/app/_models/AlertRuleSet';
import { UserService } from '../../../_services/user.service';
import { isArray } from 'util';

@Component({
  selector: 'app-fare-table-alert-filter',
  templateUrl: './fare-table-alert-filter.component.html',
  styleUrls: ['./fare-table-alert-filter.component.scss']
})

export class FareTableAlertFilterComponent implements OnInit {
  @ViewChild('alertSelect2', {static: false}) alertSelect2Instance: NgSelect2Component;
  @Input() filterTypes: Array<Select2OptionData>;

  @Input() selectedAlert: any;
  @Output() selectedAlertChange = new EventEmitter<Select2OptionData>();

  public alertRuleSets: AlertRuleSet[];
  public alertOptions: { id: string; text: string }[] = [];
  public select2Opts: Options;

  public filterMarkup = 'Any';

  constructor(
    private userService: UserService,
    private dataStore: DataStoreService
  ) {
    this.loadAlerts();
  }

  ngOnInit() {
    this.select2Opts = {
      width: '280px',
      minimumResultsForSearch: Infinity,
      placeholder: this.filterMarkup,
      escapeMarkup: markup => markup, // remove HTML stripping from placeholder, etc
      templateSelection: (state) => {
        return state.text;
      },
    };
  }
  loadAlerts(peek: boolean = false){
    let res = null;
    if (peek) {
      res = this.dataStore.withAuth(this.userService.login_token).peekAll(AlertRuleSet);
    }
    if (isArray(res) && res.length > 0) {
      this.alertRuleSets = res;
      this.alertOptions = this.alertRuleSets.map(alert => ({
        id: alert.id,
        text: alert.title
      }));
    } else  {
      this.dataStore.withAuth(this.userService.login_token).findAll(AlertRuleSet).subscribe(
        (alertRuleSets: JsonApiQueryData<AlertRuleSet>) => {
          this.alertRuleSets = alertRuleSets.getModels();
          this.alertOptions = this.alertRuleSets.map(alert => ({
            id: alert.id,
            text: alert.title
          }));
        }
      );
    }
  }
  onValueChanged(val) {
    this.selectedAlert = val.data[0];
    this.selectedAlertChange.emit(val.data[0]);
  }
  onResetFilter() {
    this.ngOnInit();
    this.selectedAlert = null;
    this.selectedAlertChange.emit({id: null, text: ""});
    // Reset the ng-select2 component directly
    this.alertSelect2Instance.writeValue('');
  }
}
