import {Component, OnInit, EventEmitter, Output, Input, ViewEncapsulation, ChangeDetectorRef, AfterContentChecked} from '@angular/core';
import { Options } from 'ng5-slider';

@Component({
  selector: 'app-fare-table-price-filter',
  templateUrl: './fare-table-price-filter.component.html',
  styleUrls: ['./fare-table-price-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FareTablePriceFilterComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  @Input() lowValue: number;
  @Input() highValue: number;

  public options: Options;
  public currentHighValue: number;
  public currentLowValue: number;
  public price: any = {};

  constructor(private cdref: ChangeDetectorRef) {
    this.lowValue = 0;
    this.highValue = 800;
  }

  ngOnInit() {
    this.currentLowValue = this.lowValue;
    this.currentHighValue = this.highValue;
    this.options = {
      floor: 0,
      ceil: 2000
    };
    this.emitValues();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }

  onLowValueChange(val) {
    this.currentLowValue = val;
  }

  onHighValueChange(val) {
    this.currentHighValue = val;
  }

  onChangeEnd(ev) {
    this.currentLowValue = ev.value;
    this.currentHighValue = ev.highValue;
    this.emitValues();
  }

  emitValues() {
    // Emit event to parent component.
    this.valueChange.emit({lowValue: this.currentLowValue, highValue: this.currentHighValue});
  }

  onResetFilter() {
    this.ngOnInit();
  }
}
