import { Component, OnInit } from '@angular/core';
import { DataStoreService } from '../_services/data-store.service';
import { Content } from '../_models/Content';
import { isNull } from 'util';
import { of } from 'rxjs';

@Component({
  selector: 'app-howto',
  templateUrl: './howto.component.html',
  styleUrls: ['./howto.component.scss']
})
export class HowtoComponent implements OnInit {
  public howToContent: string;

  constructor(private dataStore: DataStoreService) { }

  ngOnInit() {
    const peek = this.dataStore.peekRecord(Content, 'how_to');
    const observable = isNull(peek) || typeof peek === 'undefined' ? this.dataStore.findRecord(Content, 'how_to') : of(peek);
    observable.subscribe((content: Content) => {
      this.howToContent = content.value;
    });
  }
}
