import { Component, OnInit } from '@angular/core';
import { ProfileService } from "../_services/profile.service";
import { ProfileInterface } from "../_interfaces/ProfileInterface";
import { Profile } from "../_models/Profile";
import { UserService } from "../_services/user.service";
import { User } from "../_models/User";
import { UserInterface } from "../_interfaces/UserInterface";
import { LoadIndicatorService } from '../_services/load-indicator.service';
import { NgForm } from "@angular/forms";
import { VaaNoticesService } from "../_services/VaaNoticesService";
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public profile: ProfileInterface = {
    email: '',
    first_name: '',
    id: '',
    last_name: '',
    receive_partner_platform_email: null,
    is_partner_platform_user: null,
    subscription_status: 'subscribed',
  };
  public user: UserInterface =  {
    black_listed: null,
    company: '',
    drupalId: '',
    email: '',
    partner_platform_terms_and_conditions: false,
    roles: [],
  };
  public saving_user: boolean = false;
  public saving_profile: boolean = false;
  public termsAgreed: boolean = true;
  public prefsUrl = environment.vmail_url;

  constructor(private profileService: ProfileService,
              public vaaNoticesService: VaaNoticesService,
              private userService: UserService,
              private loadIndicatorService: LoadIndicatorService) { }

  ngOnInit() {
    this.loadIndicatorService.addLoading();
    this.profileService.loadProfile().subscribe((profile) => {
      this.loadIndicatorService.removeLoading();
      this.profile = profile;
    });

    this.userService.loadUserInfo().subscribe((user: User) => {
      this.user = user;
    });
  }

  onUserFormSubmit(form: NgForm) {
    if (form.invalid || !this.termsAgreed) {
      //@todo show end user message
      return;
    }

    let  values = form.value;
    this.saving_user = true;
    this.saving_profile = true;
    this.loadIndicatorService.addLoading();
    this.profileService.loadProfile(false).subscribe((profile: Profile) => {
      profile.receive_partner_platform_email = values.receive_partner_platform_email;
      if (profile.hasDirtyAttributes) {
        profile.save().subscribe((updated_profile: Profile) => {
          this.vaaNoticesService.success('Profile changes successfully saved');
          this.profile = updated_profile;
          form.reset({
            ...form.value,
            ...{
              firstName: profile.first_name,
              lastName: profile.last_name,
              emailAddress: profile.email,
              company: this.user.company
            }
          });
          this.userService.loadUserInfo().subscribe(user => {
            if (user.email != this.profile.email) {
              this.userService.loadUserInfo(false).subscribe(user => {

              })
            }
          })
        })
      }
      else {
        this.vaaNoticesService.success('Profile changes successfully saved');
      }
      this.loadIndicatorService.removeLoading();
    });
  }

  onVmailUnsubscribeFormSubmit(form: NgForm) {
    if (form.invalid) {
      //@todo show end user message
      return;
    }

    let  values = form.value;

    // @todo Handle un-subscribe.
  }

}
