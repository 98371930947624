import { Component, Input } from '@angular/core';
import { Fare } from '../../../_models/Fare';

@Component({
  selector: 'app-fare-table-on-sale-soon',
  templateUrl: './fare-table-on-sale-soon.component.html',
  styleUrls: ['./fare-table-on-sale-soon.component.scss']
})
export class FareTableOnSaleSoonComponent {
  @Input() rowData: Fare;

  constructor() { }
}
