import { Injectable } from "@angular/core";
import { NoticeMessage, NoticeType } from "../_models/NoticeMessage";
import { TextContentInterface } from "../common/vaa-notice/vaa-notice.component";


@Injectable({
  providedIn: 'root'
})
export class VaaNoticesService {
  public notices: NoticeMessage[] = [];
  addNotice(notice: NoticeMessage) {
    this.notices.push(notice);
    return notice;
  }
  clear() {
    this.notices = [];
  }
  removeNotice(notice: NoticeMessage) {
    this.notices = this.notices.filter(function (other) {
      return !notice.eq(other);
    });
  }
  public static create_notice(message?: string|TextContentInterface[], title?: string, type?: NoticeType) {
      let notice = new NoticeMessage();
      notice.noticeType = type == null ? NoticeType.notice : type;
      notice.title = title;
      notice.content = message;
    return notice
  }
  private _notice(message?: string|TextContentInterface[], title?: string, type?: NoticeType) {
    let notice = VaaNoticesService.create_notice(message, title, type);
    this.removeNotice(notice);
    return this.addNotice(notice);
  }
  success(message?: string|TextContentInterface[], title?: string) {
    return this._notice(message, title, NoticeType.success);
  }
  info(message?: string|TextContentInterface[], title?: string) {
    return this._notice(message, title, NoticeType.notice);
  }
  warning(message?: string|TextContentInterface[], title?: string) {
    return this._notice(message, title, NoticeType.warning);
  }
  error(message?: string|TextContentInterface[], title?: string) {
    return this._notice(message, title, NoticeType.error);
  }
}
