import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { HorizontalTabsService } from "../../_services/horizontal-tabs.service";
import { PasswordResetService } from "../../_services/password-reset.service";
import { Message } from "../../_interfaces/Message";
import { AuthenticationService } from "../../_services/authentication.service";
import { UserService } from "../../_services/user.service";
import { User } from "../../_models/User";
import { LoginMessage } from "../../_interfaces/LoginMessage";
import { PasswordValidatorService } from '../../_services/password-validator.service';
import { VaaNoticesService } from "../../_services/VaaNoticesService";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public message: string;
  public validPassword: boolean;
  public currentPassword: string;

  constructor(private router: Router,
              private htService: HorizontalTabsService,
              private passwordResetService: PasswordResetService,
              public vaaNoticesService: VaaNoticesService,
              private authenticationService: AuthenticationService,
              private userService: UserService,
              private passwordValidator: PasswordValidatorService) {
  }

  ngOnInit() {
    this.currentPassword = '';
    this.validPassword = false;
  }
  validateInputs(values) {
    const isValidPwd = this.passwordValidator.isValid(this.currentPassword);
    if (!isValidPwd) {
      return false;
    }
    return values.password === values.confirmPassword;
  }
  onValidatePassword(ev) {
    this.validPassword = this.passwordValidator.isValid(this.currentPassword);
  }
  onFormSubmit(f: NgForm) {
    const values = f.value;
    const isValidated = this.validateInputs(values);
    if (!isValidated) {
      return false;
    }
    if (values.password === values.confirmPassword) {
      this.passwordResetService.change_password(values.password, this.userService.login_token).subscribe((message: Message) => {
        if (message.status) {
          this.vaaNoticesService.success(message.message);
          this.userService.loadUserInfo(false).subscribe((user: User) => {
            this.authenticationService.login(user.email, values.password).subscribe((loginMessage: LoginMessage) => {
              const success = this.userService.login(loginMessage);
              if (success) {
                this.userService.save();
                this.userService.loadUserInfo(false).subscribe((user: User) => {
                  this.vaaNoticesService.success('Logged in with new credentials');
                  const command = this.userService.loginDestination(user);
                  this.router.navigateByUrl(command);
                });
              }
            });
          });
        } else {
          this.vaaNoticesService.error(message.message);
        }
      });
    }
  }
}
