import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from "./_services/user.service";
import { VaaNoticesService } from "./_services/VaaNoticesService";


@Injectable()
export class ErrorHandlerIntercept implements HttpInterceptor {
  constructor(private vaaNoticesService: VaaNoticesService, private userService: UserService) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // login page can handle this.
    if (/.*\/login$/.test(request.url)) {
      return next.handle(request);
    }
    return next.handle(request).pipe(catchError(err => {
      const error: string = err.error.message || err.statusText;
      const code: string = err.status;
      const regexp5xx = new RegExp('^5[0-9]{2}$');
      const is5xx = regexp5xx.test(code);

      if ('403' == code || '401' == code) {
        this.userService.logout();
        this.vaaNoticesService.error('Please login again.', "Authentication error.");
        return throwError(error);
      }

      if (is5xx) {
        this.vaaNoticesService.error('Oops, something went wrong. Please try again!', 'Oops');
        return throwError(error);
      }

      console.log(error);
      throw err;
    }));
  }
}
