import { Attribute, HasMany, JsonApiModel, JsonApiModelConfig } from "angular2-jsonapi";
import { UnixTimePropertyConverter } from "../_util/UnixTimePropertyConverter";
import { AlertRuleSet } from "./AlertRuleSet";
import { TravelPeriodPropertyConverter } from "../_util/TravelPeriodPropertyConverter";

let unixTimePropertyConverter: UnixTimePropertyConverter = new UnixTimePropertyConverter();

let travelPeriodPropertyConverter: TravelPeriodPropertyConverter = new TravelPeriodPropertyConverter(unixTimePropertyConverter);

class TravelPeriod {
  start_date: Date;
  end_date: Date;
  from_price: number;
  fare_brand: string;
}

@JsonApiModelConfig({
  type: 'Fare'
})
export class Fare extends JsonApiModel {
  @Attribute()
  baggage: boolean;

  @Attribute()
  beverage: boolean;

  @Attribute()
  displayed_discount: boolean;

  @Attribute()
  entertain: boolean;

  @Attribute()
  food: boolean;

  @Attribute()
  refreshment: boolean;

  @Attribute()
  seat: boolean;

  @Attribute()
  cabin: string;

  @Attribute()
  category: string;

  @Attribute()
  connection: string;

  @Attribute()
  connection_display: string;

  @Attribute()
  destination: string;

  @Attribute()
  destination_display: string;

  @Attribute()
  dir: string;

  @Attribute()
  from_price: number;

  @Attribute()
  origin: string;

  @Attribute()
  origin_display: string;

  @Attribute()
  price_sort: number;

  @Attribute()
  promo_code: string;

  @Attribute()
  tag: string;

  @Attribute()
  title: string;

  @Attribute()
  type: string;

  @Attribute({converter: travelPeriodPropertyConverter})
  travel_periods: TravelPeriod[];

  @Attribute({converter: unixTimePropertyConverter})
  sale_start: Date;

  @Attribute({converter: unixTimePropertyConverter})
  sale_end: Date;

  @Attribute()
  url: string;

  @Attribute()
  sale_title: string;

  @Attribute()
  pos_region: string;

  @Attribute()
  happy_hour: boolean;

  @Attribute()
  matched_alerts: AlertRuleSet[];

  @HasMany()
  match: AlertRuleSet[];

  @Attribute()
  current_fare: boolean;

  @Attribute()
  future_fare: boolean;

  @Attribute()
  always_on: boolean;

  get travel_period_start(): Date {
    if (this.travel_periods.length) {
      return this.travel_periods[0].start_date;
    }
    return null;
  }

  get travel_period_end(): Date {
    if (this.travel_periods.length) {
      return this.travel_periods[0].end_date;
    }
    return null;
  }

  get travel_period_from_price(): number {
    if (this.travel_periods.length) {
      return this.travel_periods[0].from_price;
    }
    return null;
  }

  get travel_period_fare_brand(): string {
    if (this.travel_periods.length) {
      return this.travel_periods[0].fare_brand;
    }
    return null;
  }
}
