import { Component, OnInit } from '@angular/core';
import { Tab } from '../_interfaces/tab';
import { HorizontalTabsService } from '../_services/horizontal-tabs.service';
import { Content } from "../_models/Content";
import { DataStoreService } from "../_services/data-store.service";
import { isNull } from "util";
import { of } from "rxjs";
import { Router } from '@angular/router';
import { RegisterService } from "../_services/register.service";
import { catchError } from "rxjs/operators";
import { UserService } from "../_services/user.service";
import { LoginMessage } from "../_interfaces/LoginMessage";
import { VaaNoticesService } from "../_services/VaaNoticesService";

interface terms {
  privacy_statement_url: string;
  privacy_policy_url: string;
}

interface registerErrors {
  fName: boolean;
  lName: boolean;
  email: boolean;
  company: boolean;
  terms: boolean;
  privacyPolicy: boolean;
  password: boolean;
  confirmPassword: boolean;
  passwordMatch: boolean;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public message: string;
  public activeTab = 'register';
  public links: Tab[];
  public activeRoute: string;
  public userFormErrors: registerErrors;
  public termsContent;
  public termUrls: terms = {
    privacy_statement_url: '',
    privacy_policy_url: ''
  };

  constructor(private htService: HorizontalTabsService,
              private dataStore: DataStoreService,
              private router: Router,
              private registerService: RegisterService,
              public vaaNoticesService: VaaNoticesService,
              private userService: UserService) { }

  ngOnInit() {
    this.activeRoute = '/public/register';
    this.links = this.htService.getUserLinks();
    this.termsContent = '';

    this.userFormErrors = {
      fName: false,
      lName: false,
      email: false,
      company: false,
      terms: false,
      privacyPolicy: false,
      password: false,
      confirmPassword: false,
      passwordMatch: false
    };

    // Get the T&C's content from the back end
    let peek = this.dataStore.peekRecord(Content, 'terms_and_conditions');
    let observable = isNull(peek) || typeof peek == 'undefined' ? this.dataStore.findRecord(Content, 'terms_and_conditions') : of(peek);
    observable.subscribe((Content) => {
      this.termsContent = Content.value;
    });

    // Retrieve from the back end the specified terms URLS
    const contentNames = ['privacy_statement_url', 'privacy_policy_url'];
    for (let key of contentNames) {
      let check = this.dataStore.peekRecord(Content, key);
      let getValue = isNull(check) || typeof check == 'undefined' ? this.dataStore.findRecord(Content, key) : of(check);
      getValue.subscribe((Content) => {
        this.termUrls[key] = Content.value;
      });
    }
  }


  monitorErrorKeys(f) {
    const fields = f.value;
    this.userFormErrors.fName = fields.firstName.length < 1;
    this.userFormErrors.lName = fields.lastName.length < 1;
    this.userFormErrors.email = f.controls.emailAddress.status === 'INVALID';
    this.userFormErrors.company = fields.company.length < 1;
    this.userFormErrors.terms = !fields.terms;
    this.userFormErrors.privacyPolicy = !fields.privacyPolicy;
  }

  isAlertFormValidated() {
    const { fName, lName, email, company, terms, privacyPolicy } = this.userFormErrors;
    return !fName && !lName && !email && !terms && !company && !privacyPolicy;
  }

  onUserFormSubmit(formData) {
    this.monitorErrorKeys(formData);
    const isFormValidated = this.isAlertFormValidated();
    if (isFormValidated === false) {
      return false;
    }
    this.registerService.register(
      formData.value.firstName,
      formData.value.lastName,
      formData.value.emailAddress,
      formData.value.company).pipe(
      catchError(err => {
        // Removing as backend errors are too technical for public, the http-error-handler interceptor handles errors nicely.
        //this.vaaNoticesService.error(err);
        return of(err.error);
      })
    ).subscribe((data: LoginMessage) => {
      if (data) {
        if (data.code == 200) {
          if (data.fully_authenticated && data.approved) {
            throw "This should never happen";
          }
          this.router.navigate(['public/password-reset/sent', {fully_authenticated: data.fully_authenticated, approved: data.approved}]);
          return;
        }
        else if (data.code == 401) {
          this.vaaNoticesService.error('401 Unauthorized');
          return;
        }
        this.vaaNoticesService.error('An Error occurred while signing up please try again.');
      }
    });
  }
}
