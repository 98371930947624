import { Injectable } from '@angular/core';

import { Router, NavigationStart, NavigationEnd  } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class LoadIndicatorService {

  // We set this to 1, because on initial load we eventually recieve a 'NavigationEnd' event
  // but are unable to listen for the corresponding 'NavigationStart' event
  private loadTally = 1;

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.addLoading();
      } else if (event instanceof NavigationEnd) {
        this.removeLoading();
      }
    });
  }

  addLoading() {
    this.loadTally++;
    this.checkStatus();
  }

  removeLoading() {
    this.loadTally--;
    this.checkStatus();
  }

  getDocumentBody() {
    return window.document.body;
  }

  checkStatus() {
    if (this.loadTally > 0) {
      this.showLoading();
    }
    else {
      this.hideLoading();
    }
  }

  showLoading() {
    const body = this.getDocumentBody();
    body.classList.add('loading');
  }

  hideLoading() {
    const body = this.getDocumentBody();
    body.classList.remove('loading');
  }

}
