import { Component, Input, OnInit } from '@angular/core';
import { Fare } from '../../../_models/Fare';
import {ShareFareService} from '../../../_services/fares/share-fare.service'

@Component({
  selector: 'app-fare-table-title',
  templateUrl: './fare-table-title.component.html',
  styleUrls: ['./fare-table-title.component.scss']
})
export class FareTableTitleComponent implements OnInit {
  @Input() rowData: Fare;

  public enableShare: boolean;
  public saleTitle: string;

  constructor(private shareFareService: ShareFareService) {}

  ngOnInit() {
    if (this.rowData.url && this.rowData.url !== '') {
      this.enableShare = true;
    } else {
      this.enableShare = false;
    }
    this.saleTitle = this.rowData.title;
  }


  openShareModal(ev) {
    ev.preventDefault();
    this.shareFareService.setFare(this.rowData);
    this.shareFareService.openShareFareDialog();
  }
}
