import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import 'daterangepicker';
import { FaresService } from '../_services/fares/fares.service';
import { DateRangePickerFixes } from '../_util/DateRangePickerFixes';
import { Fare } from '../_models/Fare';
import { DataStoreService } from '../_services/data-store.service';
import { LoadIndicatorService } from '../_services/load-indicator.service';
import { HorizontalTabsService } from '../_services/horizontal-tabs.service';
import {Tab} from "../_interfaces/tab";


@Component({
  selector: 'app-specials',
  templateUrl: './specials.component.html',
  styleUrls: ['./specials.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SpecialsComponent implements OnInit {
  public source;
  public priceFilter;
  public originFilter;
  public destinationFilter;
  public isLoading: boolean;
  activeRoute: string;
  links: Tab[] = [];


  @ViewChild('dateElement', {static: false}) dateElement: ElementRef;
  @ViewChild('dateElementSaleEnd', {static: false}) dateElementSaleEnd: ElementRef;

  constructor(private htService: HorizontalTabsService,
              private faresService: FaresService,
              public datePipe: DatePipe,
              private drPickerUtil: DateRangePickerFixes,
              private dataStore: DataStoreService,
              private loadIndicatorService: LoadIndicatorService) {

  }

  ngOnInit() {
    this.activeRoute = '/private/my-fares/specials';
    this.loadIndicatorService.addLoading();
    this.isLoading = true;
    this.links = this.htService.specialsLinks;
    this.faresService.getAllFares().subscribe((fares: Fare[]) => {
      this.loadIndicatorService.removeLoading();
      this.isLoading = false;
      this.source = fares;
    });
  }
}
