import { Component, OnInit } from '@angular/core';
import { HorizontalTabsService } from "../../_services/horizontal-tabs.service";
import { ToastrService } from "ngx-toastr";
import { PasswordResetService } from "../../_services/password-reset.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginMessage } from "../../_interfaces/LoginMessage";
import { UserService } from "../../_services/user.service";
import { User } from "../../_models/User";

@Component({
  selector: 'app-password-reset-confirm',
  templateUrl: './password-reset-confirm.component.html',
  styleUrls: ['./password-reset-confirm.component.scss']
})
export class PasswordResetConfirmComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private htService: HorizontalTabsService,
    private toast: ToastrService,
    private passwordReset: PasswordResetService,
    private userService: UserService,
    private router: Router) {
    if (!userService.logged_in) {
      this.route.params.subscribe(params => {
        this.passwordReset.confirm_reset(params['uid'], params['timestamp'], params['hash']).subscribe((loginMessage: LoginMessage) => {
          let success = userService.login(loginMessage);
          if (success) {
            userService.save();
            this.router.navigate(['/private/my-fares/profile/change-password']);
          }
          else {
            toast.success("Invalid login link");
            this.router.navigate(['/public/login']);
          }
        });
      });
    }
    else {
      userService.loadUserInfo().subscribe((user: User) => {
        toast.success("Already logged-in as " + user.email);
      });
      this.router.navigate(['/private/my-fares/profile']);
    }
  }

  ngOnInit() {

  }

}
