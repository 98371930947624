import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RadioOption } from '../../_models/RadioOption';

@Component({
  selector: 'app-specials-toggle-filter',
  templateUrl: './specials-toggle-filter.component.html',
  styleUrls: ['./specials-toggle-filter.component.scss']
})

export class SpecialsToggleFilterComponent implements OnInit {
  public radioDays: string;

  @Output() saleToggleChange = new EventEmitter<any>();
  public dateOpts: RadioOption[];
  public selectedDateOpt: string;

  constructor() { }

  ngOnInit() {
    this.dateOpts = [
      { value: 'anytime', label: 'All sales'},
      { value: 'upcoming', label: 'Upcoming sales'},
      { value: 'current', label: 'Current sales'}
    ];
    this.selectedDateOpt = this.dateOpts[0].value;
  }

  handleRadioDay($event) {
    const val = $event.value;
    // Emit event to parent component.
    this.saleToggleChange.emit(val);
  }
}
