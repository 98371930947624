import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EndpointService {

  constructor() {
  }

  public originApi = 'api/search-airport/origins';
  private destApi  = 'api/search-airport/destinations/';
  public upcomingSaleEventsEndPoint = 'api/upcoming-sales-events';

  getDestApi(iataCode) {
    if (iataCode !== '') {
      return  this.destApi + iataCode.toUpperCase();
    } else {
      throw new Error("Missing required origin IATA");
    }
  }
}
