import * as $ from 'jquery';

export class DateRangePickerFixes {

  fixCalendarRows($drPicker) {
    const $drPickerLeftCal = $drPicker.find('.drp-calendar.left');
    const $drPickerLeftCalFirstRow = $drPickerLeftCal.find('tbody tr').first();
    const $drPickerLeftCalLastRow = $drPickerLeftCal.find('tbody tr').last();
    const $drPickerRightCal = $drPicker.find('.drp-calendar.right');
    const $drPickerRightCalFirstRow = $drPickerRightCal.find('tbody tr').first();
    const $drPickerRightCalLastRow = $drPickerRightCal.find('tbody tr').last();

    // Remove any previously existing "hide-first-row" class.
    $drPickerLeftCal.removeClass('hide-first-row');
    $drPickerRightCal.removeClass('hide-first-row');
    $drPickerLeftCal.removeClass('hide-last-row');
    $drPickerRightCal.removeClass('hide-last-row');

    // Possibly hide the left calendar first row if all days are inactive.
    if( !this.checkShouldCalendarRow($drPickerLeftCalFirstRow) ) {
      // Add a CSS class opposed to calling remove() as clicking dates renders the calendar markup again with no
      // available callback.
      $drPickerLeftCal.addClass('hide-first-row');
    }

    // Possibly hide the left calendar last row if all days are inactive.
    if( !this.checkShouldCalendarRow($drPickerLeftCalLastRow) ) {
      // Add a CSS class opposed to calling remove() as clicking dates renders the calendar markup again with no
      // available callback.
      $drPickerLeftCal.addClass('hide-last-row');
    }

    // Possibly hide the right calendar first row if all days are inactive.
    if( !this.checkShouldCalendarRow($drPickerRightCalFirstRow) ) {
      // Add a CSS class opposed to calling remove() as clicking dates renders the calendar markup again with no
      // available callback.
      $drPickerRightCal.addClass('hide-first-row');
    }

    // Possibly hide the right calendar last row if all days are inactive.
    if( !this.checkShouldCalendarRow($drPickerRightCalLastRow) ) {
      // Add a CSS class opposed to calling remove() as clicking dates renders the calendar markup again with no
      // available callback.
      $drPickerRightCal.addClass('hide-last-row');
    }
  }

  checkShouldCalendarRow($row) {
    let shouldShow = false;
    $row.find('td').each(function(){
      if ( !$(this).hasClass('off') ) {
        // If even 1 table cell is visible, we must show the row.
        shouldShow = true;
        return;
      }
    });
    return shouldShow;
  }

  destroyDrPicker($drPicker) {
    $drPicker.remove();
  }
}
