import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { EndpointService } from '../api/endpoint.service';
import { location } from "../../_models/Location";
import { map } from "rxjs/operators";
import {environment} from '../../../environments/environment';
import { UserService } from "../user.service";

export const LocationAnywhere: location = {
  code: 'anywhere',
  iataCode: 'any',
  airport: 'Anywhere',
  type: 'helper'
} as location;

@Injectable({
  providedIn: 'root'
})
export class DestinationsService {
  constructor(private api: ApiService, private uri: EndpointService, private userService: UserService) {}
  public allOrigins;
  public allDestinations;

  async init() {
    this.allOrigins = await this.getOrigins().toPromise();
    this.allOrigins.push(...this.getStates());
    this.allOrigins.push(...this.getHelpers());
    this.allDestinations = this.allOrigins;
  }

  getOrigins() {
    const resource = environment.backend_url;
    const endpoint = this.uri.originApi;
    return this.api.get({endpoint, resource}, null, {headers: this.api.getAuthHeader(this.userService.login_token)}).pipe(
      map((item: any) => {
        if (item.hasOwnProperty('geographicalRegions')) {
          let locations = item.geographicalRegions.map(function (region) {
            if (region.hasOwnProperty('airports')) {
              let items = (region.airports as unknown as Array<location>);
              return items.map<location>(function (it) {
                it.type = "iata";
                it.airport = it.name;
                it.iataCode = it.code;
                it.country = it.countryName;
                it.region = this.regionName;

                if (it.geoLocation) {
                  it.latitude = it.geoLocation.latitude ? it.geoLocation.latitude : null;
                  it.longitude = it.geoLocation.longitude ? it.geoLocation.longitude : null;
                }

                return it;
              }, region);
            }
          });

          return locations.flat().filter((a) => a.metaData.vipr == false).sort((a, b) => a.airport.localeCompare(b.airport));
        }
      })
    );
  }

  getDestinations(iataCode: string) {
    const resource = environment.backend_url;
    const endpoint = this.uri.getDestApi(iataCode);
    return this.api.get({endpoint, resource}, null, {headers: this.api.getAuthHeader(this.userService.login_token)}).pipe(
      map((item: any) => {
        if (item.hasOwnProperty('geographicalRegions')) {
          let locations = item.geographicalRegions.map(function (region) {
            if (region.hasOwnProperty('airports')) {
              let items = (region.airports as unknown as Array<location>);
              return items.map<location>(function (it) {
                it.type = "iata";
                it.airport = it.name;
                it.iataCode = it.code;
                it.country = it.countryName;
                it.region = this.regionName;

                if (it.geoLocation) {
                  it.latitude = it.geoLocation.latitude ? it.geoLocation.latitude : null;
                  it.longitude = it.geoLocation.longitude ? it.geoLocation.longitude : null;
                }

                return it;
              }, region);
            }
          });

          return locations.flat().filter((a) => a.metaData.vipr == false).sort((a, b) => a.airport.localeCompare(b.airport));
        }
      })
    );
  }
  getStates(): location[] {
    return [
      {code: 'ACT', airport: 'Australian Capital Territory', type: 'state'} as location,
      {code: 'NSW', airport: 'New South Wales',              type: 'state'} as location,
      {code: 'NT',  airport: 'Northern Territory',           type: 'state'} as location,
      {code: 'QLD', airport: 'Queensland',                   type: 'state'} as location,
      {code: 'SA',  airport: 'South Australia',              type: 'state'} as location,
      {code: 'TAS', airport: 'Tasmania',                     type: 'state'} as location,
      {code: 'VIC', airport: 'Victoria',                     type: 'state'} as location,
      {code: 'WA',  airport: 'Western Australia',            type: 'state'} as location,
    ];
  }
  getHelpers(): location[] {
    return [
      LocationAnywhere,
    ];
  }
  isAnywhere(item: location) {
    return item.type === 'helper' && item.code === 'anywhere';
  }
}

