import { Component, OnInit } from '@angular/core';
import { VaaNoticesService } from "../../_services/VaaNoticesService";

@Component({
  selector: 'app-vaa-notices',
  templateUrl: './vaa-notices.component.html',
  styleUrls: ['./vaa-notices.component.scss']
})
export class VaaNoticesComponent implements OnInit {
  constructor(public vaaNoticesService: VaaNoticesService) {
  }

  ngOnInit() {
  }

}
