import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { Tab } from '../_interfaces/tab';
import { HorizontalTabsService } from '../_services/horizontal-tabs.service';
import { UserStorage } from "../_models/userStorage";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { DataStoreService } from "../_services/data-store.service";
import { User } from "../_models/User";
import { ContentService } from "../_services/content.service";
import { LoadIndicatorService } from '../_services/load-indicator.service';
import { Content } from "../_models/Content";
import { VaaNoticesService } from "../_services/VaaNoticesService";
import { NoticeType } from "../_models/NoticeMessage";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public loginForm;
  public activeRoute: string;
  public links: Tab[] = [];
  public alertFormErrors;
  public formNotSubmitted;
  public loginContent = '';
  public isLoading;

  public show;

  private login_error_notice = VaaNoticesService.create_notice([
    {
      text: "The email address or password you have entered is incorrect. Please check the information and try again, or "
    },
    {
      text: "reset your password.",
      path: "/public/password-reset"
    }
  ], null, NoticeType.error);

  private login_unsubscribed_notice = VaaNoticesService.create_notice([
    {
        text: "If the email address doesn't belong to an account, please register to access the latest specials."
    }
  ], null, NoticeType.error);

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private htService: HorizontalTabsService,
    private dataStore: DataStoreService,
    private contentService: ContentService,
    private loadIndicatorService: LoadIndicatorService,
    public vaaNoticesService: VaaNoticesService,
  ) {
    let profile = this.userService.profile;
    if (profile) {
      this.vaaNoticesService.info('Already logged in as ' + profile.email);
    }
  }

  ngOnInit() {
    this.isLoading = false;
    this.formNotSubmitted = false;
    this.activeRoute = '/public/login';
    this.links = this.htService.getUserLinks();

    this.alertFormErrors = {
      user: false,
      pass: false
    };

    if (this.userService.logged_in) {
      this.userService.loadUserInfo().subscribe((user: User) => {
        this.router.navigateByUrl(this.userService.loginDestination(user));
      })
    }

    this.contentService.getContent('login_page').subscribe((content: Content) => {
      if (content.value !== undefined) {
        this.loginContent = content.value;
      }
    });
  }

  onInput() {
    // user is changing there email or password so remove error.
    this.vaaNoticesService.removeNotice(this.login_error_notice);
    this.vaaNoticesService.removeNotice(this.login_unsubscribed_notice);
  }

  monitorErrorKeys(fields) {
    this.alertFormErrors.user = fields.username.length < 1;
    this.alertFormErrors.pass = fields.password.length < 1;
  }
  isAlertFormValidated() {
    const { user, pass } = this.alertFormErrors;
    return !user && !pass;
  }

  getLogInButton() {
    return document.getElementById('login-btn') as HTMLButtonElement;
  }

  disableLogInButton() {
    const btn = this.getLogInButton();
    btn.disabled = true;
  }

  enableLogInButton() {
    const btn = this.getLogInButton();
    btn.disabled = false;
  }

  onSubmit(f) {
    let userDetails = f.value;
    this.monitorErrorKeys(userDetails);
    const isFormValidated = this.isAlertFormValidated();
    if (isFormValidated === false) {
      this.formNotSubmitted = true;
      return false;
    }

    this.loadIndicatorService.addLoading();
    this.disableLogInButton();
    this.isLoading = true;

    this.formNotSubmitted = false;

    this.authenticationService.login(userDetails.username, userDetails.password).pipe(
      catchError(err => {
        return of(err.error)
      })
    ).subscribe(data => {
      if (data) {
        this.loadIndicatorService.removeLoading();
        this.enableLogInButton();
        if (data.login_token) {
          let success = this.userService.login(new UserStorage(data));
          if (success) {
            this.userService.save();
            this.userService.loadUserInfo(false).subscribe((user: User) => {
              let command = this.userService.loginDestination(user);
              this.router.navigateByUrl(command);
            });
          }
          else {
            this.isLoading = false;
            this.vaaNoticesService.error('Missing login data from backend');
          }
          return;
        }
        else if (data.code == 200 && data.blocked) {
          this.isLoading = false;
          this.vaaNoticesService.error('Login failed.');
          this.contentService.getContent('black_listed_message').subscribe((content: Content) => {
            if (content.value !== undefined) {
              this.vaaNoticesService.error(content.value);
            }
          });
          return;
        }
        else if (data.code == 403) {
          this.isLoading = false;
          // @todo maybe
          if (data.id) {
            this.vaaNoticesService.addNotice(this.login_unsubscribed_notice);
          }

          this.vaaNoticesService.addNotice(this.login_error_notice);
          return;
        }
        this.isLoading = false;
        this.vaaNoticesService.error('An Error occurred while signing in please try again.');
      }
    });
  }

}
