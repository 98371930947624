import { Component, OnInit } from '@angular/core';
import {DataStoreService} from "../_services/data-store.service";
import {Content} from "../_models/Content";
import {isNull} from "util";
import {of} from "rxjs";

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  public termsContent: string;

  constructor(private dataStore: DataStoreService) { }

  ngOnInit() {
    const peek = this.dataStore.peekRecord(Content, 'terms_and_conditions');
    const observable = isNull(peek) || typeof peek === 'undefined' ? this.dataStore.findRecord(Content, 'terms_and_conditions') : of(peek);
    observable.subscribe((content: Content) => {
      this.termsContent = content.value;
    });
  }
}
