import { Attribute, JsonApiModel, JsonApiModelConfig } from "angular2-jsonapi";

@JsonApiModelConfig({
  type: 'Content'
})
export class Content extends JsonApiModel {
  @Attribute()
  machine_name: string;

  @Attribute()
  value: string;
}
