import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PasswordValidatorService {

  constructor() { }

  isPunctuation(char) {
    const dec = char.charCodeAt(0);
    return (
      (dec >= 33 && dec <= 47) ||
      (dec >= 58 && dec <= 64) ||
      (dec >= 91 && dec <= 96) ||
      (dec >= 123 && dec <= 126)
    );
  }
  isLowerCase(char) {
    const dec = char.charCodeAt(0);
    return (dec >= 97 && dec <= 122);
  }
  isUpperCase(char) {
    const dec = char.charCodeAt(0);
    return (dec >= 65 && dec <= 90);
  }
  isNumber(char) {
    const dec = char.charCodeAt(0);
    return (dec >= 48 && dec <= 57);
  }
  countType(func, str) {
    let num = 0;
    [...str].forEach((char) => {
      if (func(char)) {
        num++;
      }
    });
    return num;
  }
  isValid(value) {
    const numbers = this.countType(this.isNumber, value);
    const lowercase = this.countType(this.isLowerCase, value);
    const punctuation = this.countType(this.isPunctuation, value);
    const uppercase = this.countType(this.isUpperCase, value);
    const total = value.length;
    const valid = (numbers >= 1 && lowercase >= 1 && uppercase >= 1 && punctuation >= 1 && total >= 8);
    return valid;
  }
}
