import { Component, Input, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Fare } from "../../_models/Fare";

@Component({
  selector: 'app-fare-table-combined',
  templateUrl: './fare-table-combined.component.html',
  styleUrls: ['./fare-table-combined.component.scss']
})
export class FareTableCombinedComponent implements OnInit {
  isOnSale: number;
  date: any;
  price: any;
  origin: any;
  destination: any;
  activeFilter: string;

  @Input() needFilters: boolean;
  @Input() showMatchedAlert: boolean;
  @Input() source: Fare[];

  constructor(private cdref: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }

  onActiveFilterChange(filter) {
    this.activeFilter = filter.id;
  }

}
