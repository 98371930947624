import { Component, Input, OnInit } from '@angular/core';
import { Tab } from '../../_interfaces/tab';

@Component({
  selector: 'app-horizontal-tabs',
  templateUrl: './horizontal-tabs.component.html',
  styleUrls: ['./horizontal-tabs.component.scss']
})
export class HorizontalTabsComponent implements OnInit {
  @Input() menuItems: Tab[];
  @Input() activeRoute: string;


  constructor() { }

  ngOnInit() {
  }

}
